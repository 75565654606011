import React, {useEffect, useState, useRef} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Box, Grid, Stack} from '@mui/material'
import {
  EditNote as EditNoteIcon,
  QrCodeScanner as QrCodeScannerIcon,
  SettingsBackupRestore,
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material'
import {useReactToPrint} from 'react-to-print'
import {isEqual} from 'lodash'

import DetailView from 'components/DetailView'
import {useGlobalStore} from 'provider/global_store/hook'
import {ToastType, ModalType} from 'enums'
import useEnterKeyListener from 'hooks/useEnterKeyListener'
import {useUser} from 'service/hook'
import {useListing} from 'service/hook/use_listing'
import {CopytToolbar, LoadingBar} from 'views_v2/lib/snippets'
import {CustomButton} from 'views_v2/modules/Inventory/snippets/headers'
import {
  Overview,
  ConsignorInformation,
  InventoryImages,
  InventoryPricing,
  InventoryTracking,
  Notes,
} from 'views_v2/modules/Inventory/components/cards'
import {ListingInventoryDetails} from 'views_v2/modules/Listings'
import InventoryToast from 'views_v2/modules/Inventory/components/InventoryToast'
import InventoryModals from 'views_v2/modules/Inventory/components/InventoryModals'
import {BarcodeLabels} from 'views_v2/lib/components'
import {forRemove, isConsigned} from 'util/model/product'

const ViewListing = () => {
  const {id} = useParams()
  const {isEnterprise} = useGlobalStore()
  const {user} = useUser()
  const componentRef = useRef()
  const history = useHistory()
  const [formFields, setFormFields] = useState({quantity: 1, shippingCost: '0.00'})
  const [toastType, setToastType] = useState(ToastType.UNDEFINED)
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const [printSize, setPrintSize] = useState({width: 3.5, height: 1.2})
  const [barcodeTemplates, setBarcodeTemplates] = useState([])
  const [isBarcodeLabelReady, setIsBarcodeLabelReady] = useState(false)
  const [previousListingData, setPreviousListingData] = useState(null)
  const [showBarcodeTemplate, setShowBarcodeTemplate] = useState({})

  useEnterKeyListener({
    querySelectorToExecuteClick: '#markAsSoldConfirmBtn',
  })

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    suppressErrors: true,
    documentTitle: `Copyt - ${printSize.width}x${printSize.height}`,
  })

  const routeChange = (route, data) => {
    history.push({pathname: route, data: data})
  }

  const {listingData, error, refetch} = useListing(id, {refetchOnWindowFocus: false})

  useEffect(() => {
    const interval = setInterval(() => {
      if (listingData && !isEqual(listingData, previousListingData)) {
        refetch()
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [listingData, previousListingData, refetch])

  useEffect(() => {
    if (listingData && !isEqual(listingData, previousListingData)) {
      setPreviousListingData(listingData)
    }
  }, [listingData])

  const product = listingData?.product

  if (error) {
    return (
      <div className="content">
        Failed.
        <hr />
        <button onClick={() => history.goBack()}>Back</button>
      </div>
    )
  }

  if (!listingData) return <LoadingBar />

  return (
    <>
      {isBarcodeLabelReady && (
        <Box display="none">
          <BarcodeLabels
            {...{barcodeTemplates, showBarcodeTemplate}}
            items={[listingData?.product]}
            businessName={user?.businessName || 'Copyt'}
            logo={user?.platform?.logo_url}
            url={user?.customWebsiteSearchUrl}
            ref={componentRef}
          />
        </Box>
      )}
      <Box className="p-detail-flex inventory listing">
        <CopytToolbar style={{justifyContent: 'flex-end'}}>
          <Stack direction="row" spacing={1}>
            {isEnterprise && (
              <CustomButton
                icon={<QrCodeScannerIcon />}
                title="Generate Barcode"
                onClick={() => setModalType(ModalType.BARCODE_TEMPLATE)}
                color="error"
                style={{
                  display: !product && 'disabled',
                }}
              />
            )}
            <CustomButton
              icon={<EditNoteIcon />}
              disabled={forRemove(listingData)}
              title="Edit Listing"
              onClick={() => routeChange(`/admin/listings/editListing/${id}`)}
              variant="outline"
              style={{
                display: !product && 'disabled',
              }}
            />
            {isConsigned(product) && !isEnterprise ? (
              <CustomButton
                disabled={forRemove(listingData)}
                icon={<SettingsBackupRestore />}
                title="Withdraw"
                onClick={() => setModalType(ModalType.WITHDRAW)}
              />
            ) : (
              <CustomButton
                disabled={forRemove(listingData)}
                icon={<ShoppingCartIcon />}
                title="Mark as Sold"
                onClick={() => setModalType(ModalType.MARK_AS_SOLD)}
              />
            )}
          </Stack>
        </CopytToolbar>
        <DetailView.PanelDetail>
          <Grid container gap={1}>
            <Grid item xs={12} lg={8}>
              <Grid container gap={2}>
                <Grid item xs={12} md={5.5} lg={5.8}>
                  <Overview formFields={product} isListing readOnly />
                </Grid>
                <Grid item xs={12} md={6} lg={5.8}>
                  <ListingInventoryDetails {...{product}} readOnly />
                </Grid>
              </Grid>
              <InventoryImages formFields={product} isListing />
            </Grid>
            <Grid item xs={12} lg={3.9}>
              {isEnterprise && (
                <InventoryTracking
                  {...{
                    isEnterprise,
                    setFormFields,
                  }}
                  isReadOnly
                  formFields={product}
                  location={product?.location}
                  internalSku={product?.internalSku}
                />
              )}
              <ConsignorInformation
                {...{setFormFields}}
                platforms={listingData.listingPlatforms}
                consignor={product?.consign || null}
                consignorEmail={product?.consign?.consignor?.email || ''}
                feeStructure={formFields?.feeStructure || product?.feeStructure}
                formFields={{
                  ...formFields,
                  desiredReturn: product?.desiredReturn,
                  feeStructure: product?.feeStructure,
                  location: product?.location,
                  sold: listingData?.solds?.[0],
                }}
                isConsignorEmailReadOnly
                isFeeStructureReadOnly
              />
              <InventoryPricing
                {...{isEnterprise}}
                isConsigned={product?.consign?.status?.toLowerCase() === 'listed'}
                formFields={product}
                readOnly
                isDesiredReturnReadOnly
              />
              <Notes editMode={false} notes={product?.note} />
            </Grid>
          </Grid>
          {toastType > ToastType.UNDEFINED && <InventoryToast {...{toastType, setToastType}} />}
          <InventoryModals
            {...{
              barcodeTemplates,
              formFields,
              modalType,
              printSize,
              setFormFields,
              setBarcodeTemplates,
              setModalType,
              setPrintSize,
              setToastType,
              routeChange,
              data: [product],
              showBarcodeTemplate,
            }}
            onShowBarcodeTemplate={setShowBarcodeTemplate}
            formFields={{
              ...formFields,
              consignStatus: product?.consign?.status || '',
              listingId: listingData.id,
            }}
            onGenerateBarcodesClicked={() => {
              setIsBarcodeLabelReady(true)
              setTimeout(() => {
                handlePrint()
              }, 500)
            }}
          />
        </DetailView.PanelDetail>
      </Box>
    </>
  )
}

export default ViewListing
