import React, {useState} from 'react'
import {Box, Typography, Stack} from '@mui/material'
import {isEmpty} from 'lodash'

import {useConsignmentShops} from 'service/hook'
import {PlatformsModalType} from 'views_v2/modules/Consignments/component/PlatformsModal'
import {CopytStyledButton, FieldGroup} from 'views_v2/lib/snippets'

const MdlEcgReferralCode = (p) => {
  const {onCloseModal} = p || {}
  const [referralCode, setReferralCode] = useState('')
  const [error, setError] = useState('')

  const handlePostSubmit = (returnedData) => {
    const isInvalid = ['invalid', 'existing'].some(
      (_) => typeof returnedData?.data === 'string' && returnedData?.data?.toLowerCase().includes(_),
    )

    if (isInvalid) {
      setError(returnedData?.data)
      return
    }

    if (returnedData?.data?.type === 'ECG') {
      onCloseModal()
      p.setModalType(PlatformsModalType.APPLY)
      setError('')
    }
  }

  const {submitApplication, data, isLoading} = useConsignmentShops({
    options: {},
    postSubmitCallback: handlePostSubmit,
  })

  const handleSubmit = async (e) => {
    e.preventDefault()

    const requestBody = {
      type: 'ECG',
      code: referralCode,
    }

    try {
      await submitApplication(requestBody)
    } catch (error) {
      console.error('Submission failed:', error)
    }
  }

  const handleInputChange = (e) => {
    setReferralCode(e.target.value)
    setError('')
  }

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Enter Referral Code
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} mb={2}>
        <Stack direction="column" alignItems="center" spacing={2} p={2}>
          <Typography sx={{fontWeight: 'fontWeightBold'}}>
            Hold on, this is an ECG-exclusive store.
          </Typography>
          <Typography fontSize="0.85rem">
            If you are a member of Elevate Consulting Group, enter your personalized referral code to
            unlock access to all ECG-exclusive stores.
          </Typography>

          <FieldGroup
            sLabel="Referral Code *"
            name="referral-code"
            placeholder="Code"
            hasError={!!error}
            errorMessage={error}
            value={referralCode}
            onChange={handleInputChange}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={1.5}
          mt={4}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <CopytStyledButton variant="outline" color="primary" onClick={() => onCloseModal()}>
            Cancel
          </CopytStyledButton>
          <CopytStyledButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading || isEmpty(referralCode)}
            sx={{width: '200px'}}
          >
            {isLoading ? 'Please Wait' : 'Submit Application'}
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlEcgReferralCode
