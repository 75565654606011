import React, {useState} from 'react'
import {
  Box,
  FormControlLabel,
  Typography,
  Stack,
  RadioGroup,
  Radio,
  Tooltip,
  LinearProgress,
} from '@mui/material'

import {shipment_type_options} from 'assets/data'
import {_replaceWildCards} from 'util/string_utils'
import {CopytLabel, CopytStyledButton, FieldGroup} from 'views_v2/lib/snippets'
import {ModalType} from 'enums'
import {CopytTooltip} from 'views_v2/lib/snippets'
import {Input as InputField} from 'reactstrap'

const MdlCreateLabel = (props) => {
  const {
    shipmentEvents,
    onCloseModal,
    shipmentType,
    setShipmentType,
    setModalType,
    onSaveChanges,
    createAnotherShipmentId,
    isCreatingAnotherShipment,
    isCreatingShipment,
  } = props

  const [duplicateCount, setDuplicateCount] = useState(1)
  const [selectedShipmentEventId, setSelectedShipmentEventId] = useState(null)

  const onContinue = async () => {
    switch (shipmentType) {
      case 'consignment':
        setModalType(ModalType.SELECT_APPROVED_ITEMS)
        break
      case 'order-fulfillment':
        setModalType(ModalType.ORDER_FULFILLMENT)
        break
      case 'purchase':
        setModalType(ModalType.PURCHASE)
        break
      case 'other':
        if (createAnotherShipmentId) {
          await onSaveChanges({
            createAnotherShipment: {
              productIds: [],
              method: 'DUPLICATE',
              type: 'OTHER',
              provider: 'SHIPPO',
              count: duplicateCount,
            },
          })
        } else {
          await onSaveChanges({
            createShipment: {
              productIds: [],
              method: 'DIRECT',
              type: 'OTHER',
              provider: 'SHIPPO',
            },
          })
        }
        break
      default:
        if (selectedShipmentEventId) {
          if (createAnotherShipmentId) {
            await onSaveChanges({
              createAnotherShipment: {
                productIds: [],
                method: 'DUPLICATE',
                type: 'EVENT',
                provider: 'SHIPPO',
                count: duplicateCount,
                event: {
                  id: selectedShipmentEventId,
                },
              },
            })
          } else {
            await onSaveChanges({
              createShipment: {
                productIds: [],
                method: 'DIRECT',
                type: 'EVENT',
                provider: 'SHIPPO',
                event: {
                  id: selectedShipmentEventId,
                },
              },
            })
          }
        }
        break
    }
  }

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Create Label
          </Typography>
        </Box>
      </Box>

      {isCreatingAnotherShipment ? <LinearProgress color="secondary" /> : <Stack height="4px"></Stack>}

      <Box className="modal-body" padding={0} my={2}>
        <Stack direction="column" px={2} mb={4}>
          <CopytLabel style={{fontSize: '0.9rem', marginTop: '10px', marginBottom: '10px'}}>
            Select Shipment Type
          </CopytLabel>
          <RadioGroup
            size="small"
            value={shipmentType}
            onChange={(e) => {
              console.log('e.target.value', e.target.value)
              setShipmentType(e.target.value)
            }}
          >
            {shipment_type_options.map((s) => {
              let label = ''

              switch (s?.value) {
                case 'other':
                  label = 'Create a label for another purpose.'
                  break
                case 'order-fulfillment':
                  label = 'Create a label for an item you sold.'
                  break
                case 'purchase':
                  label = 'Create a label for an item you bought.'
                  break
                default:
                  break
              }

              return (
                <CopytTooltip key={s.value} title={label} placement="right">
                  <Stack
                    width="fit-content"
                    direction="row"
                    justifyItems="center"
                    justifyContent="space-between"
                  >
                    <FormControlLabel
                      sx={{ml: 0}}
                      key={s?.value}
                      value={s.value}
                      disabled={isCreatingAnotherShipment || isCreatingShipment}
                      control={<Radio size="small" color="secondary" />}
                      label={s?.label}
                      componentsProps={{
                        typography: {
                          sx: {
                            marginLeft: '8px',
                            width: 'fit-content',
                          },
                        },
                      }}
                    />
                    {createAnotherShipmentId && s?.value == 'other' && shipmentType === 'other' && (
                      <Stack>
                        <InputField
                          type="number"
                          min={1}
                          max={20}
                          value={duplicateCount}
                          onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10)
                            if (isNaN(newValue) || newValue < 1 || newValue > 20) return
                            setDuplicateCount(newValue)
                          }}
                          style={{
                            color: 'var(--primary)',
                          }}
                        />
                        {/* <input
                          type="number"
                          min={1}
                          max={20}
                          value={duplicateCount}
                          onChange={(e) => {
                            const newValue = e.target.value

                            if (type === 'number') {
                              if (!/^\d*\.?\d*$/.test(newValue)) return
                              setDuplicateCount(newValue)
                            }
                          }}
                        /> */}
                        <CopytLabel>Duplicate Count {'(MAX 20)'}</CopytLabel>
                      </Stack>
                    )}
                  </Stack>
                </CopytTooltip>
              )
            })}
            {shipmentEvents.length
              ? shipmentEvents.map((shipmentEvent) => (
                  <CopytTooltip key={shipmentEvent.id} title={shipmentEvent.tooltip} placement="right">
                    <Stack
                      width="fit-content"
                      direction="row"
                      justifyItems="center"
                      justifyContent="space-between"
                    >
                      <FormControlLabel
                        sx={{ml: 0}}
                        key={shipmentEvent.id}
                        value="event"
                        onClick={() => setSelectedShipmentEventId(shipmentEvent.id)}
                        disabled={isCreatingAnotherShipment || isCreatingShipment}
                        control={<Radio size="small" color="secondary" />}
                        label={shipmentEvent.name}
                        componentsProps={{
                          typography: {
                            sx: {
                              marginLeft: '8px',
                              width: 'fit-content',
                            },
                          },
                        }}
                      />
                      {createAnotherShipmentId && shipmentType === 'event' && (
                        <Stack>
                          <InputField
                            type="number"
                            min={1}
                            max={20}
                            value={duplicateCount}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10)
                              if (isNaN(newValue) || newValue < 1 || newValue > 20) return
                              setDuplicateCount(newValue)
                            }}
                            style={{
                              color: 'var(--primary)',
                            }}
                          />
                          <CopytLabel>Duplicate Count {'(MAX 20)'}</CopytLabel>
                        </Stack>
                      )}
                    </Stack>
                  </CopytTooltip>
                ))
              : null}
          </RadioGroup>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton
            disabled={isCreatingAnotherShipment || isCreatingShipment}
            variant="outline"
            color="primary"
            onClick={onCloseModal}
          >
            Cancel
          </CopytStyledButton>
          <CopytStyledButton
            disabled={isCreatingAnotherShipment || isCreatingShipment}
            variant="contained"
            color="primary"
            onClick={onContinue}
          >
            Continue
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlCreateLabel
