import React, {memo} from 'react'
import {Box, Grid} from '@mui/material'
import DetailView from 'components/DetailView'
import CopytCard from 'components/CopytCard'
import {
  CreditCardSettings,
  CustomizePlatform,
  StoreLocations,
  LoginDetails,
  CurrencySettings,
  ShippingDetails,
} from './cards'
import {useGlobalStore} from 'provider/global_store/hook'
import QRCodeSettings from './cards/QRCodeSettings'

const General = (p) => {
  const {
    visible,
    setModalType,
    locations,
    onSaveChanges,
    onDeleteLocation,
    setLocationToUpdate,
    loading,
    hasMOP,
    ccInfo,
    handleCheckbox,
    setCCInfo,
    loadingMain,
    submitMain,
  } = p
  const {isEnterprise} = useGlobalStore()

  return (
    <DetailView.Panel isHidden={!visible}>
      <Box display="flex" justifyContent="space-around">
        <CopytCard className="account-big-card card-layout">
          <Grid container gap={1} justifyContent="space-between">
            <Grid item xs={12} lg={5.9}>
              <CurrencySettings />
              <CreditCardSettings {...{hasMOP, ccInfo, setModalType}} />
              <LoginDetails {...{setModalType}} />
              <QRCodeSettings />
            </Grid>
            <Grid item xs={12} lg={6}>
              {isEnterprise && (
                <StoreLocations
                  {...{
                    locations,
                    setModalType,
                    onSaveChanges,
                    onDeleteLocation,
                    setLocationToUpdate,
                    loading,
                  }}
                />
              )}
              <ShippingDetails {...{handleCheckbox, ccInfo, setCCInfo, loadingMain, submitMain}} />
              {isEnterprise && <CustomizePlatform />}
            </Grid>
          </Grid>
        </CopytCard>
      </Box>
    </DetailView.Panel>
  )
}

// Shipping Details

export default memo(General)
