import React from 'react'
import {range} from 'lodash'
import {Box, Button, Stack, Typography} from '@mui/material'
import {InfoOutlined as InfoOutlinedIcon} from '@mui/icons-material'
import QRCode from 'react-qr-code'
import {useHistory} from 'react-router-dom'

// Todo: revamp barcode template
import {TextTemplate} from 'views_v2/modules/Inventory/components/modal-contents/MdlBarcodeTemplate'
import {CopytStyledButton} from 'views_v2/lib/snippets'

const QRGenerator = ({hasCustomUrl}) => {
  const history = useHistory()
  const routeChange = (route) => history.push(route)

  if (!hasCustomUrl) {
    return (
      <Stack pt={4} spacing={3} alignItems="center">
        <Stack alignItems="center">
          <InfoOutlinedIcon fontSize="large" sx={{color: 'red'}} />
          <Typography fontSize="20px" fontWeight={600}>
            No valid link
          </Typography>
          <Typography fontSize="18px">Please add a URL in the settings and try again.</Typography>
        </Stack>

        <CopytStyledButton
          variant="contained"
          color="primary"
          sx={{width: 200}}
          onClick={() => routeChange('/admin/settings')}
        >
          Go to settings
        </CopytStyledButton>
      </Stack>
    )
  }

  return (
    <Stack mt={2} direction="row">
      <Box width="52%">
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="flex-start"
          columnGap={2}
          alignItems="flex-end"
          spacing={2}
          px={2}
        >
          {range(1, 2).map((index) => (
            <Button
              key={`template[${index}]`}
              sx={{
                backgroundColor: index === 1 ? 'var(--lighter) !important' : '#ff8d723b !important',
                boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.24)',
                color: 'var(--primary) !important',
                width: '105px',
                height: '40px',
                lineHeight: '1rem',
                fontSize: '0.8rem',
                fontWeight: 600,
                borderRadius: '10px !important',
              }}
            >
              {`Template ${index}`}
            </Button>
          ))}
        </Stack>
      </Box>

      <Box flex={1} backgroundColor="var(--lighter)" borderRadius={2} mx={2} p={2}>
        <Stack
          backgroundColor="white"
          h="100%"
          p={2}
          spacing={3}
          borderRadius={2}
          justifyContent="center"
          alignItems="center"
        >
          <TextTemplate>Item Name</TextTemplate>
          <Stack alignItems="center" justifyContent="center">
            <TextTemplate>Size</TextTemplate>
            <TextTemplate>Item Condition</TextTemplate>
            <TextTemplate>Box Condition</TextTemplate>

            <QRCode value="copyt.io" size={130} />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  )
}

export default QRGenerator
