import React, {forwardRef} from 'react'
import Barcode from 'react-barcode'
import {Box, Typography, Stack} from '@mui/material'
import {isEmpty} from 'lodash'

import copytLogo from 'assets/img/copyt-logo-sidebar.svg'
import {getCurrency} from 'util/model/setting'
import {isConsigned} from 'util/model/product'
import {getInitials, _getValue} from 'util/string_utils'
import {QRPrintPreview} from './barcode-template'

const getItem = (key, item) => {
  const currency = getCurrency()
  if (key === 'price' || key === 'desiredReturn') {
    return currency.format(_getValue(item[key]))
  }

  return item[key] || ''
}

const BarcodeLabels = forwardRef(({items, barcodeTemplates, logo, showBarcodeTemplate, url}, ref) => {
  const {printSize, topStrings, bottomStrings, template} = barcodeTemplates || {}
  const hasConsignorCode = showBarcodeTemplate?.consignorCode

  const hasNoTitle = [8, 9].includes(template)

  const isDefaultSize = printSize.width === 3.5

  const width = isDefaultSize ? 336 : 211.2
  const height = isDefaultSize ? 115.2 : 105.6

  const printStyles = `
    @media print {
      @page {
        size: ${width}px ${height}px;
        margin-top: 0 !important;
        padding-top: 0 !important;
      }
    }
  `

  const generateLogo = (style) => (
    <Box
      className="barcode-image"
      component="img"
      sx={{
        ...style,
        maxWidth: '85%',
        maxHeight: '85%',
        width: 'auto',
        height: 'auto',
      }}
      src={logo || copytLogo}
    />
  )

  const generateTypography = (text, style) => (
    <Typography {...style} color="black" fontWeight={600}>
      {text}
    </Typography>
  )

  const printBody = (item) => {
    const generateBarcode = (style) => <Barcode value={item.internalSku} fontOptions="bold" {...style} />

    const renderConsignorCode = (style) =>
      hasConsignorCode &&
      isConsigned(item) && (
        <Box
          sx={{
            ...style,
            width: '40px',
            position: 'relative',
            marginTop: isDefaultSize ? '100px' : '90px',
            marginRight: '10px',
          }}
        >
          <Typography color="black" fontWeight={600}>
            C{getInitials(item?.consign?.consignor?.firstName)}
            {getInitials(item?.consign?.consignor?.lastName)}
          </Typography>
        </Box>
      )

    const generateGridItems = (strings, fontSize) => {
      return strings.map((str, index) => {
        const value = getItem(str?.id, item)

        return (
          <Box
            key={index}
            lineHeight={isDefaultSize ? '17px' : '14px'}
            color="black"
            fontWeight={600}
            fontSize={fontSize}
            visibility={str?.isHidden ? 'hidden' : ''}
          >
            {value}
          </Box>
        )
      })
    }

    if (hasNoTitle) {
      const location = getItem(topStrings[0], item)
      return (
        <Box display="flex" flexDirection="row" maxHeight="120px">
          <Box
            width="35%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {generateLogo()}
          </Box>
          <Box
            flexDirection="column"
            width="64%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {generateBarcode({height: 40, width: isDefaultSize ? 1.7 : 1, fontSize: 20})}
            {template === 9 &&
              generateTypography(location, {
                fontSize: location?.length > 19 && !isDefaultSize ? '12px' : '16px',
              })}
          </Box>
          {renderConsignorCode()}
        </Box>
      )
    } else if (template === 1) {
      return (
        <Box display="flex" flexDirection="row" maxHeight="120px">
          <Box
            width="35%"
            maxHeight={isDefaultSize ? '90px' : '80px'}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            {generateLogo()}
            {bottomStrings.map((str, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="center"
                alignItems="center"
                visibility={str?.isHidden ? 'hidden' : ''}
              >
                {generateTypography(getItem(str?.id, item), {fontSize: '0.8rem'})}
              </Box>
            ))}
          </Box>
          <Stack justifyContent="center" alignItems="center" width="64%">
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2.4}>
              {topStrings
                .filter((_, index) => index < topStrings.length - 1)
                .map((str, index) => (
                  <Box
                    key={index}
                    lineHeight={isDefaultSize ? '17px' : '14px'}
                    color="black"
                    fontWeight={600}
                    fontSize={isDefaultSize ? '0.9rem' : '0.65rem'}
                    visibility={str?.isHidden ? 'hidden' : ''}
                  >
                    {getItem(str?.id, item)}
                  </Box>
                ))}
            </Stack>
            {generateBarcode({
              height: isDefaultSize ? 40 : 30,
              width: isDefaultSize ? 2 : 1.1,
              fontSize: 18,
            })}
          </Stack>
          {renderConsignorCode()}
        </Box>
      )
    } else if (template === 4) {
      return (
        <Box display="flex" flexDirection="row" maxHeight="120px">
          <Box
            width="35%"
            maxHeight={isDefaultSize ? '90px' : '80px'}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            {generateLogo()}
            {bottomStrings.map((str, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="center"
                alignItems="center"
                visibility={str?.isHidden ? 'hidden' : ''}
              >
                {generateTypography(getItem(str?.id, item), {
                  fontSize: isDefaultSize ? '1.5rem' : '1rem',
                })}
              </Box>
            ))}
          </Box>
          <Box
            flexDirection="column"
            width="64%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {topStrings.map((str, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="center"
                alignItems="center"
                visibility={str?.isHidden ? 'hidden' : ''}
              >
                {generateTypography(getItem(str?.id, item), {
                  fontSize: isDefaultSize ? '1.7rem' : '1rem',
                })}
              </Box>
            ))}
            {generateBarcode({height: 40, width: isDefaultSize ? 1.7 : 1, fontSize: 20})}
          </Box>
          {renderConsignorCode()}
        </Box>
      )
    } else if (template === 5) {
      return (
        <Box display="flex" flexDirection="row" maxHeight="120px">
          <Box
            width="35%"
            maxHeight={isDefaultSize ? '90px' : '80px'}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            {generateLogo()}
          </Box>
          <Box
            flexDirection="column"
            width="64%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {topStrings.map((str, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="center"
                alignItems="center"
                visibility={str?.isHidden ? 'hidden' : ''}
              >
                {generateTypography(getItem(str?.id, item), {
                  fontSize: isDefaultSize ? '1.7rem' : '1rem',
                })}
              </Box>
            ))}
            {generateBarcode({height: 40, width: isDefaultSize ? 1.7 : 1, fontSize: 20})}
          </Box>
          {renderConsignorCode()}
        </Box>
      )
    } else if (template === 7) {
      const showCode = hasConsignorCode && isConsigned(item)
      return (
        <Stack direction="row">
          <Box
            width="35%"
            maxHeight={isDefaultSize ? '90px' : '80px'}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            {generateLogo()}
            {bottomStrings.map((str, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="center"
                alignItems="center"
                visibility={str?.isHidden ? 'hidden' : ''}
              >
                {generateTypography(getItem(str?.id, item), {
                  fontSize: isDefaultSize ? '2rem' : '1.5rem',
                })}
              </Box>
            ))}
          </Box>

          <Stack direction="column" width="62%">
            <Stack>
              <Stack direction="row" justifyContent="space-between">
                {generateGridItems(topStrings, isDefaultSize ? '0.9rem' : '0.55rem')}
              </Stack>
              <Box flexGrow={1} height="10px" />
              <Stack justifyContent="center" alignItems="center">
                {generateBarcode({
                  height: showCode ? 26 : 30,
                  width: isDefaultSize ? 2.3 : 1,
                  fontSize: showCode ? 16 : 25,
                })}
              </Stack>
            </Stack>
            {showCode && (
              <Box>
                <Typography color="black" fontWeight={600} textAlign="right">
                  C{getInitials(item?.consign?.consignor?.firstName)}
                  {getInitials(item?.consign?.consignor?.lastName)}
                </Typography>
              </Box>
            )}
          </Stack>
        </Stack>
      )
    } else if (template === 10) {
      return (
        <Stack direction="row">
          <Stack gap={1}>{generateGridItems(topStrings, isDefaultSize ? '0.9rem' : '0.55rem')}</Stack>
          {generateBarcode({height: 40, width: isDefaultSize ? 1.7 : 1, fontSize: 12})}
          {renderConsignorCode({
            position: 'absolute',
            bottom: 30,
            right: isDefaultSize ? 10 : 40,
          })}
        </Stack>
      )
    }

    return (
      <Box display="flex" flexDirection="row" maxHeight="130px">
        <Stack
          width="35%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {generateLogo({maxWidth: '75%', maxHeight: '75%'})}
        </Stack>
        <Stack width="65%">
          <Stack direction="row" justifyContent="space-between">
            {generateGridItems(topStrings, isDefaultSize ? '0.8rem' : '0.55rem')}
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            {generateGridItems(bottomStrings, isDefaultSize ? '0.8rem' : '0.55rem')}
          </Stack>
          <Stack justifyContent="center" alignItems="center">
            {generateBarcode({height: 26, width: isDefaultSize ? 2.3 : 1, fontSize: 16})}
          </Stack>
        </Stack>
        {renderConsignorCode()}
      </Box>
    )
  }

  if (showBarcodeTemplate?.template === 'qr') {
    return (
      <Box ref={ref}>
        <QRPrintPreview items={items} url={url} />
      </Box>
    )
  }

  return (
    <Box ref={ref}>
      <style>{printStyles}</style>
      {items.map((item, index) => {
        let fontSize = '18px'

        if (item?.title) {
          const titleLength = item.title.length
          fontSize = titleLength > 50 ? '14px' : titleLength > 40 ? '18px' : '16px'

          if (!isDefaultSize) {
            fontSize = titleLength > 40 ? '10px' : '12px'
          } else if (hasNoTitle) {
            fontSize = '8px'
          }
        }

        if (template === 10) {
          return (
            <Box key={index} flex={1} height={isDefaultSize ? '170px' : '155px'}>
              <Box
                width={`${printSize?.width + (isDefaultSize ? 1.7 : 1)}in`}
                border="1px solid white"
                height={isDefaultSize ? '160px' : '145px'}
                maxHeight={isDefaultSize ? '160px' : '145px'}
              >
                <Stack spacing={1}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    px={1}
                    pt={1}
                    alignItems="center"
                  >
                    <Box
                      width="35%"
                      maxHeight={isDefaultSize ? '60px' : '50px'}
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                    >
                      {generateLogo({
                        objectFit: 'contain',
                        '@media print': {
                          maxWidth: '50%',
                          maxHeight: '50%',
                        },
                      })}
                    </Box>
                    <Box width="70%">
                      <Typography
                        color={!hasNoTitle && !isEmpty(item?.title) ? 'black' : 'white'}
                        textAlign="center"
                        lineHeight={1.2}
                        fontWeight={600}
                        marginY="10px"
                        {...{fontSize}}
                      >
                        {hasNoTitle ? 'n/a' : item.title || 'n/a'}
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack direction="row" px={1} gap={2} justifyContent="space-evenly">
                    {bottomStrings.map((str, index) => (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        visibility={str?.isHidden ? 'hidden' : ''}
                      >
                        {generateTypography(getItem(str?.id, item), {
                          fontSize: isDefaultSize ? '2rem' : '1.5rem',
                        })}
                      </Box>
                    ))}
                    {printBody(item)}
                  </Stack>
                </Stack>
              </Box>
            </Box>
          )
        }

        return (
          <Box key={index} flex={1} height={isDefaultSize ? '170px' : '155px'}>
            <Box
              width={`${printSize?.width + (isDefaultSize ? 1.7 : 1)}in`}
              border="1px solid white"
              height={isDefaultSize ? '160px' : '145px'}
              maxHeight={isDefaultSize ? '160px' : '145px'}
            >
              <Box>
                <Typography
                  color={!hasNoTitle && !isEmpty(item?.title) ? 'black' : 'white'}
                  textAlign="center"
                  lineHeight={1.2}
                  fontWeight={600}
                  marginY="10px"
                  {...{fontSize}}
                >
                  {hasNoTitle ? 'n/a' : item.title || 'n/a'}
                </Typography>
              </Box>
              {printBody(item)}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
})

export default BarcodeLabels
