import {useState, useEffect} from 'react'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import Qs from 'querystring'
import {captureException} from '@sentry/react'

import {paths} from 'constant/api'
import {useToastMessage} from 'components'
import {createRequestOptions} from 'util/network'

const getConsignmentShops = async (getAccessTokenSilently, queries) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(
      `${paths?.platform}/shops?${Qs.stringify({...queries})}`,
      createRequestOptions(token),
    )

    return response.data
  } catch (error) {
    captureException(error)
    console.log('Error retrieving consignment shops', error)
    throw error
  }
}

const getApplications = async (getAccessTokenSilently, queries) => {
  try {
    const token = await getAccessTokenSilently()

    const response = await axios.get(
      `${paths?.application}?${Qs.stringify({...queries})}`,
      createRequestOptions(token),
    )
    return response.data.data
  } catch (error) {
    captureException(error)
    console.log('Error retrieving applications', error)
    throw error
  }
}

const getShopApplications = async (getAccessTokenSilently, queries) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(
      `${paths?.application}/shop?${Qs.stringify({...queries})}`,
      createRequestOptions(token),
    )
    return response.data.data
  } catch (error) {
    captureException(error)
    console.log('Error retrieving shop applications', error)
    throw error
  }
}

const getPendingApplications = async (getAccessTokenSilently, queries) => {
  try {
    const token = await getAccessTokenSilently()

    const response = await axios.get(
      `${paths?.application}?status=pending&${Qs.stringify({...queries})}`,
      createRequestOptions(token),
    )
    return response.data.data
  } catch (error) {
    captureException(error)
    console.log('Error retrieving applications', error)
    throw error
  }
}

const getCompletedApplications = async (getAccessTokenSilently, queries) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(
      `${paths?.application}?status=done&${Qs.stringify({...queries})}`,
      createRequestOptions(token),
    )
    return response.data.data
  } catch (error) {
    captureException(error)
    console.log('Error retrieving applications', error)
    throw error
  }
}

const getStoreLocations = async (getAccessTokenSilently, userId) => {
  try {
    if (!userId) return []
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${paths.location}/user/${userId}`, createRequestOptions(token))
    return response.data?.data
  } catch (error) {
    console.log(error)
  }
}

export const useConsignmentShops = ({options = {}, queries = {}, postSubmitCallback}, userId = '') => {
  const {getAccessTokenSilently} = useAuth0()
  const queryClient = useQueryClient()
  const {showToast} = useToastMessage()
  const [fetchConsignmentShopsEnabled, setFetchConsignmentShopsEnabled] = useState(true)
  const [fetchApplicationsEnabled, setFetchApplicationsEnabled] = useState(true)
  const [fetchShopApplicationsEnabled, setFetchShopApplicationsEnabled] = useState(true)
  const [fetchPendingApplicationsEnabled, setFetchPendingApplicationsEnabled] = useState(true)
  const [fetchCompletedApplicationsEnabled, setFetchCompletedApplicationsEnabled] = useState(true)
  const [fetchStoreLocationsEnabled, setFetchStoreLocationsEnabled] = useState(true)

  const {
    isLoading: isConsignmentShopsLoading,
    isError: isConsignmentShopsError,
    isFetched: isConsignmentShopsFetched,
    refetch: consignmentShopsRefetch,
    data: consignmentShops = [],
    error: consignmentShopsError,
  } = useQuery('consignmentShops', () => getConsignmentShops(getAccessTokenSilently, queries), {
    ...options,
    enabled: fetchConsignmentShopsEnabled,
    keepPreviousData: true,
  })

  const {
    isLoading: isApplicationsLoading,
    isError: isApplicationsError,
    isFetched: isApplicationsFetched,
    refetch: applicationsRefetch,
    data: applications = [],
    error: applicationsError,
  } = useQuery('applications', () => getApplications(getAccessTokenSilently, queries), {
    enabled: fetchApplicationsEnabled,
  })

  const {
    isLoading: isShopApplicationsLoading,
    isError: isShopApplicationsError,
    isFetched: isShopApplicationsFetched,
    refetch: shopApplicationsRefetch,
    data: shopApplications = [],
    error: shopApplicationsError,
  } = useQuery('shopApplications', () => getShopApplications(getAccessTokenSilently, queries), {
    enabled: fetchShopApplicationsEnabled,
  })

  const {
    isLoading: isPendingApplicationsLoading,
    isError: isPendingApplicationsError,
    isFetched: isPendingApplicationsFetched,
    refetch: pendingApplicationsRefetch,
    data: pendingApplications = [],
    error: pendingApplicationsError,
  } = useQuery('pendingApplications', () => getPendingApplications(getAccessTokenSilently, queries), {
    enabled: fetchPendingApplicationsEnabled,
  })

  const {
    isLoading: isCompletedApplicationsLoading,
    isError: isCompletedApplicationsFetchedError,
    isFetched: isCompletedApplicationsFetched,
    refetch: completedApplicationsRefetch,
    data: completedApplications = [],
    error: completedApplicationsError,
  } = useQuery(
    'completedApplications',
    () => getCompletedApplications(getAccessTokenSilently, queries),
    {
      enabled: fetchCompletedApplicationsEnabled,
    },
  )

  const {
    isLoading: isStoreLocationsLoading,
    isError: isStoreLocationsFetchedError,
    isFetched: isStoreLocationsFetched,
    refetch: storeLocationsRefetch,
    data: storeLocations = [],
    error: storeLocationsError,
  } = useQuery(['storeLocations', userId], () => getStoreLocations(getAccessTokenSilently, userId), {
    keepPreviousData: true,
    enabled: true,
    staleTime: Infinity,
  })

  const submitApplication = useMutation(
    async (requestBody) => {
      const token = await getAccessTokenSilently()
      const result = await axios.post(`${paths?.application}`, requestBody, createRequestOptions(token))
      return result
    },
    {
      onSuccess: async (returnedData) => {
        consignmentShops.forEach((store) => {
          if (store.id === returnedData.data.ownerId) {
            store.applications = [returnedData.data]
          }
        })
        queryClient.invalidateQueries('availablePlatforms')
        pendingApplicationsRefetch()
        completedApplicationsRefetch()

        if (postSubmitCallback) {
          postSubmitCallback(returnedData)
        }

        const isInvalid = ['invalid', 'existing'].some(
          (_) => typeof returnedData?.data === 'string' && returnedData.data.toLowerCase().includes(_),
        )

        showToast({
          variant: isInvalid ? 'danger' : 'success',
          title: isInvalid ? 'Error' : 'Success',
          body: isInvalid ? returnedData?.data : 'Your application has been sent',
        })
      },
      onError: async (error) => {
        showToast({
          variant: 'danger',
          title: 'Error',
          body: error?.response?.data?.msg || 'Failure to process request!',
        })
      },
    },
  )

  const {data, isLoading} = submitApplication

  const updateApplicationStatus = useMutation(
    async (data) => {
      const {applicationId, status} = data

      const token = await getAccessTokenSilently()

      return await axios.post(
        `${paths?.application}/${status === 'approved' ? 'approve' : 'reject'}`,
        {
          id: applicationId,
        },
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (data, variables, context) => {
        applications.forEach((app) => {
          if (app.id === data.data.id) {
            app.status = data.data.status
          }
        })
        shopApplicationsRefetch()
      },
    },
    {
      onMutate: async () => {
        shopApplicationsRefetch()
      },
    },
    {
      onError: async (error) => {
        shopApplicationsRefetch()
      },
    },
  )

  const updateNote = useMutation(
    async ({type, id, value}) => {
      const token = await getAccessTokenSilently()

      const url = type === 'product' ? `${paths.product}/note/${id}` : `${paths.application}/note/${id}`

      return await axios.patch(url, value, createRequestOptions(token))
    },
    {
      onSuccess: () => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: 'Note has been added',
        })
        consignmentShopsRefetch()
      },
      onMutate: () => {
        showToast({
          variant: 'info',
          title: 'Updating',
          body: 'Updating note...',
        })
      },
      onError: (e) => {
        showToast({
          variant: 'danger',
          title: 'Error',
          body: 'Failed to update the note',
        })
      },
    },
  )

  useEffect(() => {
    if (fetchConsignmentShopsEnabled) {
      consignmentShopsRefetch()
      setFetchConsignmentShopsEnabled(false)
    }
  }, [fetchConsignmentShopsEnabled, consignmentShopsRefetch])

  useEffect(() => {
    if (fetchApplicationsEnabled) {
      applicationsRefetch()
      setFetchApplicationsEnabled(false)
    }
  }, [fetchApplicationsEnabled, applicationsRefetch])

  useEffect(() => {
    if (fetchShopApplicationsEnabled) {
      shopApplicationsRefetch()
      setFetchShopApplicationsEnabled(false)
    }
  }, [fetchShopApplicationsEnabled, shopApplicationsRefetch])

  useEffect(() => {
    if (fetchPendingApplicationsEnabled) {
      pendingApplicationsRefetch()
      setFetchPendingApplicationsEnabled(false)
    }
  }, [fetchPendingApplicationsEnabled, pendingApplicationsRefetch])

  useEffect(() => {
    if (fetchCompletedApplicationsEnabled) {
      completedApplicationsRefetch()
      setFetchCompletedApplicationsEnabled(false)
    }
  }, [fetchCompletedApplicationsEnabled, completedApplicationsRefetch])

  useEffect(() => {
    if (fetchStoreLocationsEnabled) {
      storeLocationsRefetch()
      setFetchStoreLocationsEnabled(false)
    }
  }, [fetchStoreLocationsEnabled, storeLocationsRefetch])

  return {
    applications: applications || [],
    shopApplications: shopApplications || [],
    shopApplicationsRefetch: shopApplicationsRefetch || [],
    isShopApplicationsLoading: isShopApplicationsLoading,
    isShopApplicationsError: isShopApplicationsError,
    consignmentShops: consignmentShops || [],
    onUpdateNote: updateNote.mutate,
    submitApplication: submitApplication.mutate,
    submitApplicationAsync: submitApplication.mutateAsync,
    updateApplicationStatus: updateApplicationStatus.mutate,
    consignmentShopsRefetch: () => setFetchConsignmentShopsEnabled(true),
    pendingApplications: pendingApplications || [],
    isPendingApplicationsLoading: isPendingApplicationsLoading,
    isPendingApplicationsError: isPendingApplicationsError,
    pendingApplicationsRefetch: () => setFetchPendingApplicationsEnabled(true),
    completedApplications: completedApplications || [],
    isCompletedApplicationsLoading: isCompletedApplicationsLoading,
    isCompletedApplicationsFetchedError: isCompletedApplicationsFetchedError,
    completedApplicationsRefetch: () => setFetchCompletedApplicationsEnabled(true),
    storeLocations: storeLocations || [],
    storeLocationsRefetch: () => setFetchStoreLocationsEnabled(true),
    data,
    isLoading,
  }
}

export default useConsignmentShops
