import React from 'react'
import {TextareaAutosize} from '@mui/material'

import CopytCard from 'components/CopytCard'

export const Notes = (props) => {
  const {onChange, editMode, notes} = props

  return (
    <CopytCard className="inventory-tracking" style={{marginBottom: '12px'}}>
      <CopytCard.Header>
        <CopytCard.Text tag="div">Notes</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        <TextareaAutosize
          style={{
            width: '100%',
            borderRadius: 6,
            padding: 10,
          }}
          disabled={!editMode}
          value={notes}
          minRows={10}
          maxRows={10}
          onChange={(e) => onChange((formFields) => ({...formFields, note: e?.target?.value}))}
        />
      </CopytCard.Body>
    </CopytCard>
  )
}

export default Notes
