import React, {useEffect, useMemo, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Grid, Box} from '@mui/material'
import {
  ViewList as ViewListIcon,
  ShoppingCart as ShoppingCartIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import {isEmpty} from 'lodash'

import {ModalType} from 'enums'
import {useGlobalStore} from 'provider/global_store/hook'
import {apiConfig} from 'config'
import {paths} from 'constant/api'
import DetailView from 'components/DetailView'
import {ReactComponent as ListingFailureSvg} from 'assets/img/ListingFailure.svg'
import useEnterKeyListener from 'hooks/useEnterKeyListener'
import {createRequestOptions} from 'util/network'
import {isConsigned} from 'util/model/product'
import {getFee} from 'util/model/consignment'
import {usePlatformAccepted} from 'service/hook/use_platform'
import {useStoreSettings, useCredential} from 'service/hook'
import {
  Overview,
  InventoryPricing,
  InventoryImages,
  Shipping,
  ConsignorInformation,
  InventoryTracking,
  InventoryPlatforms,
  Notes,
} from 'views_v2/modules/Inventory/components/cards'
import {ListingInventoryDetails} from 'views_v2/modules/Listings'
import InventoryModals from 'views_v2/modules/Inventory/components/InventoryModals'
import {CustomButton} from 'views_v2/modules/Inventory/snippets/headers'
import mw from 'views_v2/modules/Listings/middleware'
import {getMerchant, CLOVER} from './middleware/getMerchant'

// TODO: Remove this once Settings/Account Settings and Settings/Security
// use the CopytCard component
import 'views/inventory/AddItem.scss'

export const checkIfObjEmpty = (obj) => _.values(obj).some((x) => x === '' || x === null)

const CreateListing = (props) => {
  const {pathname} = useLocation()
  const id = pathname.replace('/admin/listings/createListing/', '')
  const {data: marketPlatformsAccepted} = usePlatformAccepted()
  const {locations: settingsStoreLocations} = useStoreSettings()
  const {credential} = useCredential()
  const {getAccessTokenSilently} = useAuth0()

  const [item, setItem] = useState({})
  const [formFields, setFormFields] = useState({
    quantity: 1,
    shippingCost: '0.00',
    soldPlatform: null,
    soldPrice: '',
  })
  const [platformOptions, setPlatformOptions] = useState([])
  const [platformSelection, setPlatformSelection] = useState([])
  const [addLoading, setAddLoading] = useState(false)
  const [hasMop, setHasMop] = useState(false)
  const history = useHistory()
  const {isEnterprise} = useGlobalStore()
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const [title, setTitle] = useState('Listing Failure')
  const [content, setContent] = useState(null)
  const [isDefaultLocationInserted, setIsDefaultLocationInserted] = useState(false)
  const [cloverStoreLocations, setCloverStoreLocations] = useState([])
  const [cloverSelectedStoreLocation, setCloverSelectedStoreLocation] = useState()

  const handleCheckbox = (id) => {
    const index = platformSelection.indexOf(id)
    if (index > -1) {
      setPlatformSelection(platformSelection.filter((i) => i !== id))
      if (id === CLOVER) setCloverSelectedStoreLocation(undefined)
    } else {
      setPlatformSelection([...platformSelection, id])
      if (id === CLOVER && !isEmpty(cloverStoreLocations) && cloverStoreLocations.length === 1) {
        setCloverSelectedStoreLocation(cloverStoreLocations[0].value)
      }
    }
  }
  useEnterKeyListener({querySelectorToExecuteClick: '#markAsSoldConfirmBtn'})

  const markAsSold = async () => {
    try {
      var token = await getAccessTokenSilently()

      const r = await axios.post(
        `${paths.listing}/mark-sold`,
        {
          productId: id,
          platformId: formFields.soldPlatform.value || formFields.soldPlatform,
          price: Number(formFields.soldPrice),
          shippingFee: Number(formFields.shippingCost),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      routeChange(`/admin/listings/viewSold/${r.data.data.id}`)
    } catch (e) {
      Sentry.captureException(e)
      setTitle('Listing failed to mark as sold')
      setContent(
        (e && e.response && e.response.data) ||
          'Item is currently consigned and cannot be mark as sold manually',
      )
      setModalType(ModalType.LISTING_FAILURES)
    }
  }

  const onFormSubmit = async () => {
    const price = Number(item.desiredReturn)

    var formError
    if (!item.id) {
      formError = 'List Item is required'
    } else if (platformSelection.length === 0) {
      formError = 'Platform is required'
    } else if (!price) {
      formError = 'List Price is required'
    } else {
      formError = null
    }

    const cloverRefId = cloverSelectedStoreLocation?.split(':')[2]

    const data = {
      price: price,
      productId: item.id,
      platformIds: platformSelection,
      refIds: {
        clover: cloverRefId,
      },
    }

    if (!formError) {
      try {
        setAddLoading(true)
        const token = await getAccessTokenSilently()

        const response = await axios.post(`${paths.listing}`, data, {
          headers: {Authorization: `Bearer ${token}`},
        })

        setAddLoading(false)
        if (response.status === 201) {
          routeChange('/admin/inventory/viewInventory', {status: 'job created'})
        }
      } catch (err) {
        Sentry.captureException(err)

        const failedPlatformModal = platformSelection.map((id) =>
          platformOptions.find((p) => p.id === id),
        )
        setContent(
          <Box>
            Could not post to{' '}
            {failedPlatformModal
              .map((fp, i) =>
                i > 0 && i === failedPlatformModal.length - 1 ? 'and ' + fp.label : fp.label,
              )
              .join(', ')}
            .
            <ListingFailureSvg />
          </Box>,
        )
        setModalType(ModalType.LISTING_FAILURES)
        setAddLoading(false)
      }
    }
  }

  const routeChange = (route, data) => history.push({pathname: route, data: data})

  const handleUpdate = (e) => {
    const target = e.target
    setItem((s) => ({...s, [target.name]: target.value}))
  }

  useEffect(() => {
    if (!item || !Array.isArray(settingsStoreLocations) || !platformSelection) return
    if (isDefaultLocationInserted) return
    if (item.location) {
      for (const csl of cloverStoreLocations) {
        if (csl.label === item.location) {
          setCloverSelectedStoreLocation(csl.value)
          setPlatformSelection((prevState) => [...prevState, csl.value.split(':')[1]])
          setIsDefaultLocationInserted(true)
        }
      }
    }
  }, [item, settingsStoreLocations, platformSelection, isDefaultLocationInserted])

  useEffect(() => {
    if (!credential || !Array.isArray(settingsStoreLocations)) return

    if (credential.data) {
      const merchants = getMerchant(credential)

      const cloverMapedtoreLocations = []
      for (const settingsStoreLocation of settingsStoreLocations) {
        for (const merchant of merchants) {
          if (merchant.value?.storeLocationId === settingsStoreLocation.id) {
            cloverMapedtoreLocations.push({
              label: settingsStoreLocation.name,
              value: `clover:${merchant.platform.id}:${merchant.refId}`,
            })
            break
          }
        }
      }
      setCloverStoreLocations(cloverMapedtoreLocations)
    }
  }, [settingsStoreLocations, credential?.data])

  useEffect(() => {
    ;(async () => {
      try {
        const token = await getAccessTokenSilently()

        const response = await axios.get(`${apiConfig.api_url}/product/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        let item = response.data.data
        setItem(item)

        let price = 0

        if (item && item.images) {
          var tempArr = []
          var tempIndex = 0

          for (var i = 0; i < item.images.length; i++) {
            tempArr.push(item.images[i].url)
            switch (item.images[i].index) {
              case 'outside':
                item.images[i].index = '1'
                break
              case 'inside':
                item.images[i].index = '2'
                break
              case 'top':
                item.images[i].index = '3'
                break
              case 'back':
                item.images[i].index = '4'
                break
              case 'soles':
                item.images[i].index = '5'
                break
              case 'sizeTag':
                item.images[i].index = '6'
                break
              case 'box':
                item.images[i].index = '7'
                break
              default:
                break
            }
            if (tempIndex < parseInt(item.images[i].index)) {
              tempIndex = parseInt(item.images[i].index)
            }
          }

          if (item.desiredReturn) {
            price = item.desiredReturn.split('.')
          }
        }

        // note that user allowed platforms already implement on v2 api
        let supportedPlatforms = await axios.get(`${paths.platform}?type=market`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const allowedPlatforms = supportedPlatforms.data.data.map((i) => ({
          label: i.name,
          id: i.id,
          value: i.id,
          hasCred: i.credentials.length > 0,
        }))

        setPlatformOptions(allowedPlatforms)

        setPlatformSelection((prevState) => [
          ...prevState,
          ...allowedPlatforms
            .filter((platform) => platform.hasCred)
            .filter((platform) => platform.value !== CLOVER)
            .map((marketPlatform) => marketPlatform.value),
        ])
      } catch (e) {
        Sentry.captureException(e)
      }
    })()
  }, [getAccessTokenSilently])

  const getMopInfo = async () => {
    try {
      const token = await getAccessTokenSilently()
      const response = await axios.get(
        `${apiConfig.api_url}/payment/method`,
        createRequestOptions(token),
      )

      setHasMop(response?.data?.data?.method?.object === 'payment_method')
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  useEffect(() => {
    try {
      getMopInfo()
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [])

  const soldPlatformOptions = useMemo(
    () => [
      // ...(platformOptions?.map((i) => ({label: i.name, value: i.id})) || []),
      ...platformOptions,
    ],
    [platformOptions],
  )

  useEffect(() => {
    const elsewhere = soldPlatformOptions.find((plat) => plat.label === 'Elsewhere')
    setFormFields({...formFields, soldPlatform: elsewhere})
  }, [soldPlatformOptions])

  return (
    <Box className="p-detail-flex inventory listing">
      <Box className="p-detail-toolbar no-print" justifyContent="space-between !important">
        <CustomButton
          color="primary"
          variant="outline"
          icon={<CloseIcon />}
          title="Cancel"
          onClick={() => routeChange(`/admin/inventory/viewItem/${id}`)}
        />
        <Box display="flex">
          {!marketPlatformsAccepted.length || !hasMop ? (
            <CustomButton
              tooltipTitle="Add a payment method in your Settings to list."
              disabled={platformSelection?.some(
                (s) => s === CLOVER && !cloverSelectedStoreLocation?.includes('clover'),
              )}
              icon={<ViewListIcon />}
              title="List Item"
              onClick={() => routeChange('/admin/settings/account')}
              sx={{marginRight: 0.5, opacity: '0.5'}}
            />
          ) : (
            <CustomButton
              disabled={
                addLoading ||
                platformSelection?.some(
                  (s) => s === CLOVER && !cloverSelectedStoreLocation?.includes('clover'),
                )
              }
              icon={<ViewListIcon />}
              title={
                addLoading ? (
                  <Loader
                    type="TailSpin"
                    color="white"
                    height={20}
                    width={20}
                    className="import-button"
                  />
                ) : (
                  'List Item'
                )
              }
              onClick={onFormSubmit}
              sx={{marginRight: 0.5}}
            />
          )}
          <CustomButton
            icon={<ShoppingCartIcon />}
            title="Mark as sold"
            onClick={() => setModalType(ModalType.MARK_AS_SOLD)}
          />
        </Box>
      </Box>
      <DetailView.Panel scrollable>
        <Grid container gap={1}>
          <Grid item xs={12} lg={8}>
            <Grid container gap={2}>
              <Grid item xs={12} md={5.5} lg={5.8}>
                <Overview formFields={item} isListing readOnly />
              </Grid>
              <Grid item xs={12} md={6} lg={5.8}>
                <ListingInventoryDetails product={item} readOnly />
              </Grid>
            </Grid>
            <InventoryImages formFields={item} isListing />
          </Grid>
          <Grid item xs={12} lg={3.5}>
            <Shipping formFields={item} handleInputChange={handleUpdate} />
            <InventoryPricing
              {...{isEnterprise}}
              formFields={item}
              isConsigned={isConsigned(item)}
              readOnly
            />
            <InventoryPlatforms
              {...{
                isEnterprise,
                platformSelection,
                platformOptions,
                cloverStoreLocations,
                handleCheckbox,
                cloverSelectedStoreLocation,
              }}
              onChangeCloverDropdown={(e) => {
                if (e) {
                  setCloverSelectedStoreLocation(e.value)
                  setPlatformSelection([
                    ...platformSelection.filter(
                      (platform) => platform !== cloverSelectedStoreLocation?.split(':')[1],
                    ),
                    e.value.split(':')[1],
                  ])
                } else {
                  setPlatformSelection(
                    platformSelection.filter(
                      (platform) => platform !== cloverSelectedStoreLocation?.split(':')[1],
                    ),
                  )
                  setCloverSelectedStoreLocation(undefined)
                }
              }}
            />
            {isEnterprise && (
              <InventoryTracking
                {...{
                  isEnterprise,
                  setFormFields,
                }}
                isReadOnly
                formFields={item}
                internalSku={item?.internalSku}
                location={item?.location}
              />
            )}
            {isEnterprise && (
              <>
                <ConsignorInformation
                  setFormFields={setFormFields}
                  consignor={item?.consign}
                  consignorEmail={item?.consign?.consignor?.email}
                  feeStructure={getFee(item?.consign)}
                  formFields={{
                    ...formFields,
                    desiredReturn: item?.desiredReturn,
                  }}
                  isConsignorEmailReadOnly
                />
                <Notes editMode onChange={setFormFields} notes={formFields?.note} />
              </>
            )}
          </Grid>
        </Grid>
        <InventoryModals
          {...{
            formFields,
            modalType,
            setFormFields,
            setModalType,
            routeChange,
            markAsSold,
            title,
            content,
          }}
          shouldUsePropFunc
        />
      </DetailView.Panel>
    </Box>
  )
}

export default CreateListing
