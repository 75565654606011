import React from 'react'
import {Box, Typography, Stack} from '@mui/material'
import QRCode from 'react-qr-code'

import {getCurrency} from 'util/model/setting'
import {_getValue} from 'util/string_utils'

const getItem = (key, item) => {
  const currency = getCurrency()
  if (key === 'price' || key === 'desiredReturn') {
    return currency.format(_getValue(item[key]))
  }

  return item[key] || ''
}

const QRPrintPreview = ({items, url = 'copyt.io'}) => {
  const printStyles = `
    @media print {
      @page {
        size: 115px 250px;
        margin-top: 0 !important;
        padding-top: 0 !important;
      }
    }
  `

  return (
    <Box>
      <style>{printStyles}</style>
      {items.map((item, index) => {
        return (
          <Box key={index} height="370px" width="172px">
            <Box width="168px">
              <Stack
                backgroundColor="white"
                h="100%"
                p={2}
                spacing={3}
                borderRadius={2}
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  lineHeight="15px"
                  color="black"
                  fontSize={15}
                  textAlign="center"
                  fontWeight={700}
                >
                  {item?.title}
                </Typography>
                <Stack alignItems="center" justifyContent="center">
                  <Typography lineHeight="13px" color="black" fontSize={13}>
                    {item?.size}
                  </Typography>
                  <Typography lineHeight="13px" color="black" fontSize={13}>
                    {item?.condition}
                  </Typography>
                  <Typography lineHeight="13px" color="black" fontSize={13}>
                    {item?.boxCondition}
                  </Typography>

                  <Box marginTop={3}>
                    <QRCode
                      value={`${url}/search?q=${item?.title?.trim()?.replace(/ /g, '+')}`}
                      size={130}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default QRPrintPreview
