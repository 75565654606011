import React, {useEffect, useState} from 'react'
import {
  Box,
  FormControlLabel,
  Typography,
  Stack,
  Checkbox,
  Chip,
  LinearProgress,
  useMediaQuery,
} from '@mui/material'
import {get, includes, isEmpty, values} from 'lodash'

import {_replaceWildCards, capitalizeText, formatName} from 'util/string_utils'
import {useGlobalStore} from 'provider/global_store/hook'
import {CopytLabel, CopytStyledButton, FieldGroup} from 'views_v2/lib/snippets'
import {ModalType} from 'enums'
import CountrySelect from 'views_v2/lib/components/CountrySelect'
import {useAddress} from 'service/hook/useShipments'
import {useStoreSettings} from 'service/hook'
import {SearchView} from 'views_v2/lib/components'
import mw from '../middleware'
import formatAddress from '../middleware/formatAddress'
import CreatableSelect from 'react-select/creatable'
import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'

const MdlAddress = (props) => {
  const {
    addresses,
    onSaveChanges,
    setAddresses,
    setModalType,
    shipment,
    isGeneratingShipment,
    shippingDetails,
    generateShipmentError,
    shipmentConstant,
    setRecipientId,
    generatedShipmentErrors,
  } = props
  const {getAccessTokenSilently} = useAuth0()
  const {isEnterprise, user} = useGlobalStore()
  const {address} = useAddress()
  const {locations: _locations} = useStoreSettings()
  const [isUseDefaultAddress, setIsUseDefaultAddress] = useState({
    sender: false,
    recipient: false,
  })
  const [recipientCheckboxLabel, setRecipientCheckboxLabel] = useState('')
  const [openSelectRecipientLocationsPanel, setOpenSelectRecipientLocationsPanel] = useState(false)
  const [recipientLocationOptions, setRecipientLocatiionOptions] = useState([])
  const isMobile = useMediaQuery('(max-width:1000px)')

  useEffect(() => {
    if (shipment?.recipient) {
      setRecipientId(shipment.recipient.id)
    }
  }, [])

  useEffect(() => {
    switch (shipment?.type) {
      case 'ORDER_FULFILLMENT': {
        setRecipientCheckboxLabel('Use customer address')

        if (!isEmpty(addresses?.sender)) break

        setIsUseDefaultAddress((prev) => ({
          ...prev,
          sender: true,
        }))

        if (!isEnterprise) {
          setAddresses({
            ...addresses,
            sender: {
              street1: shippingDetails.addressShipping,
              street2: '',
              city: shippingDetails?.cityShipping,
              state: shippingDetails?.stateShipping,
              zip: shippingDetails?.zipShipping,
              name: formatName(user),
              email: user?.email,
              phone: user?.phoneNumber,
              country: 'US',
            },
          })
        }

        const defaultLocation = !isEmpty(_locations) ? _locations?.find((s) => s?.isDefault) : null

        if (!defaultLocation) break

        setAddresses({
          ...addresses,
          sender: {
            street1: defaultLocation.address,
            street2: '',
            city: defaultLocation.city,
            state: defaultLocation.state,
            zip: defaultLocation.zipcode,
            name: formatName(user),
            email: user?.email,
            phone: user?.phoneNumber,
            country: 'US',
          },
        })

        break
      }
      case 'PURCHASE': {
        setRecipientCheckboxLabel('Use my address')

        if (!isEnterprise || !isEmpty(addresses?.recipient)) break

        setIsUseDefaultAddress((prev) => ({
          ...prev,
          recipient: true,
        }))

        const uniqueLocations = [...new Set(shipment.products.map(({product}) => product.location))]

        if (uniqueLocations.length === 1) {
          const [uniqueLocation] = uniqueLocations
          const defaultLocation = !isEmpty(_locations)
            ? _locations?.find((s) => s?.name.toLowerCase() === uniqueLocation.toLowerCase())
            : null

          if (!defaultLocation) break

          setAddresses({
            ...addresses,
            recipient: {
              street1: defaultLocation.address,
              street2: '',
              city: defaultLocation.city,
              state: defaultLocation.state,
              zip: defaultLocation.zipcode,
              name: formatName(user),
              email: user?.email,
              phone: user?.phoneNumber,
              country: 'US',
            },
          })
        }

        if (uniqueLocations.length > 1) {
          const uniqueLocationsSelection = !isEmpty(_locations)
            ? _locations?.filter((location) => uniqueLocations.includes(location?.name))
            : []
          setRecipientLocatiionOptions(uniqueLocationsSelection)
          setOpenSelectRecipientLocationsPanel(true)
        }

        break
      }
      case 'CONSIGNMENT': {
        setRecipientCheckboxLabel('Use store address')

        if (isEnterprise || !isEmpty(addresses?.recipient)) break

        const consigneeId = shipment.products[0].product.consign.consignee.id
        const locations = shipment.products.map(({product}) => product.location)

        const uniqueLocations = [...new Set(locations)]

        ;(async () => {
          try {
            const token = await getAccessTokenSilently()
            const response = await axios.get(
              `${paths.location}/user/${consigneeId}`,
              createRequestOptions(token),
            )

            const recipientLocations = response.data?.data

            if (uniqueLocations.length === 1) {
              const itemLocation = uniqueLocations[0]
              const defaultLocation = !isEmpty(recipientLocations)
                ? recipientLocations?.find((s) => s?.name.toLowerCase() === itemLocation.toLowerCase())
                : null

              setRecipientId(consigneeId)

              if (defaultLocation) {
                setAddresses({
                  ...addresses,
                  recipient: {
                    street1: defaultLocation.address,
                    street2: '',
                    city: defaultLocation.city,
                    state: defaultLocation.state,
                    zip: defaultLocation.zipcode,
                    name: formatName(user),
                    email: user?.email,
                    phone: user?.phoneNumber,
                    country: 'US',
                  },
                })
              }
            }

            if (uniqueLocations.length > 1) {
              // const uniqueLocationsSelection = !isEmpty(_locations)
              //   ? _locations?.filter((location) => uniqueLocations.includes(location?.name))
              //   : []
              // setRecipientLocatiionOptions(uniqueLocationsSelection)
              // setOpenSelectRecipientLocationsPanel(true)
            }
          } catch (error) {
            console.error(error)
          }
        })()

        break
      }
      case 'OTHER': {
        setRecipientCheckboxLabel('Use my address')
        break
      }
      case 'EVENT': {
        if (!shipment?.event) return

        setRecipientCheckboxLabel('Use event address')

        setIsUseDefaultAddress((prev) => ({
          ...prev,
          recipient: true,
        }))

        setAddresses({
          ...addresses,
          recipient: {
            street1: shipment?.event?.recipientAddress.street1,
            street2: shipment?.event?.recipientAddress.street2,
            city: shipment?.event?.recipientAddress.city,
            state: shipment?.event?.recipientAddress.state,
            zip: shipment?.event?.recipientAddress.zip,
            name: shipment?.event?.recipientAddress.name,
            email: shipment?.event?.recipientAddress.email,
            phone: shipment?.event?.recipientAddress.phone,
            country: shipment?.event?.recipientAddress.country,
          },
        })
      }
    }
  }, [_locations, isEnterprise])

  const onSearch = (key, searchId) => {
    if (key === 'recipient') {
      const sAddress = address?.recipientAddresses?.find(
        (s) => s?.id === searchId || s?.object_id === searchId,
      )
      setAddresses({...addresses, recipient: sAddress})
    } else {
      const sAddress = address?.senderAddresses?.find(
        (s) => s?.id === searchId || s?.object_id === searchId,
      )
      setAddresses({...addresses, sender: sAddress})
    }
  }

  const handleChange = (key, target) => {
    const {name, value} = target

    setAddresses((prevAddresses) => ({
      ...prevAddresses,
      [key]: {
        ...prevAddresses[key],
        [name]: value,
      },
    }))
  }

  const handleCheckbox = (value, key) => {
    if (key === 'sender' && value) {
      if (!isEnterprise) {
        setAddresses({
          ...addresses,
          sender: {
            street1: shippingDetails.addressShipping,
            street2: '',
            city: shippingDetails?.cityShipping,
            state: shippingDetails?.stateShipping,
            zip: shippingDetails?.zipShipping,
            name: formatName(user),
            email: user?.email,
            phone: user?.phoneNumber,
            country: 'US',
          },
        })
      } else {
        const defaultLocation = !isEmpty(_locations) ? _locations?.find((s) => s?.isDefault) : null
        if (defaultLocation) {
          setAddresses({
            ...addresses,
            sender: {
              street1: defaultLocation.address,
              street2: '',
              city: defaultLocation.city,
              state: defaultLocation.state,
              zip: defaultLocation.zipcode,
              name: formatName(user),
              email: user?.email,
              phone: user?.phoneNumber,
              country: 'US',
            },
          })
        }
      }
    }

    if (key === 'recipient') {
      if (!value) {
        setOpenSelectRecipientLocationsPanel(false)
      } else {
        switch (shipment?.type) {
          case 'ORDER_FULFILLMENT': {
            if (!isEnterprise) break
            break
          }
          case 'PURCHASE': {
            if (!isEnterprise) break

            const uniqueLocations = [...new Set(shipment.products.map(({product}) => product.location))]

            if (uniqueLocations.length === 1) {
              const [uniqueLocation] = uniqueLocations
              const defaultLocation = !isEmpty(_locations)
                ? _locations?.find((s) => s?.name.toLowerCase() === uniqueLocation.toLowerCase())
                : null

              if (!defaultLocation) break

              setAddresses({
                ...addresses,
                recipient: {
                  street1: defaultLocation.address,
                  street2: '',
                  city: defaultLocation.city,
                  state: defaultLocation.state,
                  zip: defaultLocation.zipcode,
                  name: formatName(user),
                  email: user?.email,
                  phone: user?.phoneNumber,
                  country: 'US',
                },
              })
            }

            if (uniqueLocations.length > 1) {
              const uniqueLocationsSelection = !isEmpty(_locations)
                ? _locations?.filter((location) => uniqueLocations.includes(location?.name))
                : []
              setRecipientLocatiionOptions(uniqueLocationsSelection)
              setOpenSelectRecipientLocationsPanel(true)
            }

            break
          }
          case 'CONSIGNMENT': {
            setRecipientCheckboxLabel('Use store address')

            if (isEnterprise) break

            const consigneeId = shipment.products[0].product.consign.consignee.id
            const locations = shipment.products.map(({product}) => product.location)

            const uniqueLocations = [...new Set(locations)]
            ;(async () => {
              try {
                const token = await getAccessTokenSilently()
                const response = await axios.get(
                  `${paths.location}/user/${consigneeId}`,
                  createRequestOptions(token),
                )

                const recipientLocations = response.data?.data

                if (uniqueLocations.length === 1) {
                  const itemLocation = uniqueLocations[0]
                  const defaultLocation = !isEmpty(recipientLocations)
                    ? recipientLocations?.find(
                        (s) => s?.name.toLowerCase() === itemLocation.toLowerCase(),
                      )
                    : null

                  setRecipientId(consigneeId)

                  if (defaultLocation) {
                    setAddresses({
                      ...addresses,
                      recipient: {
                        ownerId: consigneeId,
                        street1: defaultLocation.address,
                        street2: '',
                        city: defaultLocation.city,
                        state: defaultLocation.state,
                        zip: defaultLocation.zipcode,
                        name: formatName(user),
                        email: user?.email,
                        phone: user?.phoneNumber,
                        country: 'US',
                      },
                    })
                  }
                }

                if (uniqueLocations.length > 1) {
                  // const uniqueLocationsSelection = !isEmpty(_locations)
                  //   ? _locations?.filter((location) => uniqueLocations.includes(location?.name))
                  //   : []
                  // setRecipientLocatiionOptions(uniqueLocationsSelection)
                  // setOpenSelectRecipientLocationsPanel(true)
                }
              } catch (error) {
                console.error(error)
              }
            })()

            break
          }
          case 'OTHER': {
            const defaultLocation = !isEmpty(_locations) ? _locations?.find((s) => s?.isDefault) : null
            if (defaultLocation) {
              setAddresses({
                ...addresses,
                recipient: {
                  street1: defaultLocation.address,
                  street2: '',
                  city: defaultLocation.city,
                  state: defaultLocation.state,
                  zip: defaultLocation.zipcode,
                  name: formatName(user),
                  email: user?.email,
                  phone: user?.phoneNumber,
                  country: 'US',
                },
              })
            }
            break
          }
        }
      }
    }

    setIsUseDefaultAddress((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            <span>Enter Address</span>{' '}
            <Chip label={capitalizeText(shipment?.type)} color="warning" size="small" />
          </Typography>
        </Box>
      </Box>

      {isGeneratingShipment ? <LinearProgress color="secondary" /> : <Stack height="4px"></Stack>}

      <Box overflow="auto">
        <Box className="modal-body" maxHeight="calc(100vh - 200px)">
          <Stack direction={isMobile ? 'column' : 'row'} pl={2} flex={1}>
            {['sender', 'recipient'].map((key, index) => {
              const suggestions =
                !address?.isLoading && !isEmpty(address)
                  ? index
                    ? address.recipientAddresses
                    : address.senderAddresses
                  : []
              const stateHasError =
                (key === 'sender' && generateShipmentError?.address_from?.state) ||
                (key === 'recipient' && generateShipmentError?.address_to?.state)
              const stateSelectFieldStyle = {
                control: (provided) => ({
                  ...provided,
                  borderColor: stateHasError ? 'red !important' : undefined,
                }),
              }
              const isDisabledField = shipment?.event && key === 'recipient'

              return (
                <React.Fragment key={`${key}[${index}]`}>
                  <Stack
                    key={`${key}[${index}]`}
                    bgcolor={index ? 'var(--secondary)' : 'transparent'}
                    direction="column"
                    paddingRight={3}
                    paddingLeft={index ? 3 : 1}
                    paddingY={2}
                    flex={1}
                  >
                    <Box>
                      <CopytLabel
                        style={{
                          fontSize: '1rem',
                          marginTop: '10px',
                          marginBottom: '16px',
                          textTransform: 'capitalize',
                        }}
                      >
                        {key} Address
                      </CopytLabel>
                    </Box>
                    <Stack marginBottom={2}>
                      <FormControlLabel
                        sx={{ml: 0}}
                        disabled={isGeneratingShipment || isDisabledField}
                        onChange={(e) => handleCheckbox(!isUseDefaultAddress[key], key)}
                        control={<Checkbox checked={isUseDefaultAddress[key]} />}
                        label={key === 'sender' ? 'Use my address' : recipientCheckboxLabel}
                        componentsprops={{
                          typography: {
                            sx: {
                              marginLeft: '8px',
                            },
                          },
                        }}
                      />
                    </Stack>
                    {openSelectRecipientLocationsPanel && key === 'recipient' ? (
                      <Stack spacing={2}>
                        <Typography fontWeight={600}>Select Address</Typography>
                        <Stack
                          sx={{
                            gap: 2,
                            maxHeight: '350px',
                            overflowY: 'auto',
                          }}
                        >
                          {recipientLocationOptions.map((recipientLocationOption) => (
                            <Box
                              key={index}
                              px={1}
                              border="2px solid"
                              borderRadius="6px"
                              isDisabled
                              onClick={() => {
                                setOpenSelectRecipientLocationsPanel(false)
                                setAddresses({
                                  ...addresses,
                                  recipient: {
                                    street1: recipientLocationOption.address,
                                    street2: '',
                                    city: recipientLocationOption.city,
                                    state: recipientLocationOption.state,
                                    zip: recipientLocationOption.zipcode,
                                    name: formatName(user),
                                    email: user?.email,
                                    phone: user?.phoneNumber,
                                    country: 'US',
                                  },
                                })
                              }}
                              sx={{
                                '&:hover, &:active': {
                                  outline: '2px solid',
                                  background: 'var(--lighter)',
                                },
                                cursor: 'pointer',
                              }}
                            >
                              <Stack padding={1}>
                                <Typography fontSize={14}>
                                  {formatAddress(recipientLocationOption)}
                                </Typography>
                              </Stack>
                            </Box>
                          ))}
                        </Stack>
                      </Stack>
                    ) : (
                      <>
                        <Stack marginBottom={2}>
                          <SearchView
                            disabled={isUseDefaultAddress[key] || isGeneratingShipment || isDisabledField}
                            suggestions={mw.createAddressSuggestions(suggestions)}
                            onSearch={(s) => onSearch(key, s)}
                            style={{
                              height: '35px',
                              marginBottom: '6px',
                            }}
                            inputProps={{
                              sx: {
                                '&:hover, &:focus': {
                                  background: 'transparent',
                                  outline: 'none',
                                },
                              },
                            }}
                            placeholder={`Search past ${key}...`}
                          />
                        </Stack>
                        <FieldGroup
                          disabled={isGeneratingShipment || isDisabledField}
                          hasError={
                            (key === 'sender' && generateShipmentError?.address_from?.name) ||
                            (key === 'recipient' && generateShipmentError?.address_to?.name)
                          }
                          errorMessage={
                            (key === 'sender' &&
                              generateShipmentError?.address_from?.name?._errors[0]) ||
                            (key === 'recipient' && generateShipmentError?.address_to?.name?._errors[0])
                          }
                          sLabel="Name *"
                          value={addresses[key]?.name}
                          name="name"
                          onChange={(e) => handleChange(key, e.target)}
                        />
                        <Stack direction="row" spacing={2}>
                          <FieldGroup
                            disabled={isGeneratingShipment || isDisabledField}
                            hasError={
                              (key === 'sender' && generateShipmentError?.address_from?.email) ||
                              (key === 'recipient' && generateShipmentError?.address_to?.email)
                            }
                            errorMessage={
                              (key === 'sender' &&
                                generateShipmentError?.address_from?.email?._errors[0]) ||
                              (key === 'recipient' &&
                                generateShipmentError?.address_to?.email?._errors[0])
                            }
                            sLabel="Email *"
                            value={addresses[key]?.email}
                            name="email"
                            onChange={(e) => handleChange(key, e.target)}
                          />
                          <FieldGroup
                            disabled={isGeneratingShipment || isDisabledField}
                            hasError={
                              (key === 'sender' && generateShipmentError?.address_from?.phone) ||
                              (key === 'recipient' && generateShipmentError?.address_to?.phone)
                            }
                            errorMessage={
                              (key === 'sender' &&
                                generateShipmentError?.address_from?.phone?._errors[0]) ||
                              (key === 'recipient' &&
                                generateShipmentError?.address_to?.phone?._errors[0])
                            }
                            sLabel="Phone Number *"
                            placeholder="Enter phone number"
                            value={addresses[key]?.phone}
                            onChange={(e) => handleChange(key, {name: 'phone', value: e})}
                          />
                        </Stack>
                        <Stack>
                          <Box>
                            <CopytLabel sx={{marginBottom: '8px'}}>Country *</CopytLabel>
                            <CountrySelect
                              selected="US"
                              isDisabled={true || isGeneratingShipmen || isDisabledField}
                              onSelect={(country) => {
                                console.log({country})
                                handleChange(key, {name: 'country', value: country})
                              }}
                            />
                          </Box>
                        </Stack>
                        <FieldGroup
                          disabled={isGeneratingShipment || isDisabledField}
                          hasError={
                            (key === 'sender' && generateShipmentError?.address_from?.street1) ||
                            (key === 'recipient' && generateShipmentError?.address_to?.street1)
                          }
                          errorMessage={
                            (key === 'sender' &&
                              generateShipmentError?.address_from?.street1?._errors[0]) ||
                            (key === 'recipient' &&
                              generateShipmentError?.address_to?.street1?._errors[0])
                          }
                          sLabel="Street *"
                          value={addresses[key]?.street1}
                          name="street1"
                          onChange={(e) => handleChange(key, e.target)}
                        />
                        <Stack direction="row" spacing={2}>
                          <FieldGroup
                            disabled={isGeneratingShipment || isDisabledField}
                            hasError={
                              (key === 'sender' && generateShipmentError?.address_from?.street2) ||
                              (key === 'recipient' && generateShipmentError?.address_to?.street2)
                            }
                            errorMessage={
                              (key === 'sender' &&
                                generateShipmentError?.address_from?.street2?._errors[0]) ||
                              (key === 'recipient' &&
                                generateShipmentError?.address_to?.street2?._errors[0])
                            }
                            sLabel="Street line 2 (Optional)"
                            value={addresses[key]?.street2}
                            name="street2"
                            onChange={(e) => handleChange(key, e.target)}
                          />
                          <FieldGroup
                            disabled={isGeneratingShipment || isDisabledField}
                            hasError={
                              (key === 'sender' && generateShipmentError?.address_from?.city) ||
                              (key === 'recipient' && generateShipmentError?.address_to?.city)
                            }
                            errorMessage={
                              (key === 'sender' &&
                                generateShipmentError?.address_from?.city?._errors[0]) ||
                              (key === 'recipient' &&
                                generateShipmentError?.address_to?.city?._errors[0])
                            }
                            sLabel="City *"
                            value={addresses[key]?.city}
                            name="city"
                            onChange={(e) => handleChange(key, e.target)}
                          />
                        </Stack>
                        <Stack direction="row" gap={2}>
                          <Box width="100%" marginTop={0.6}>
                            <CopytLabel sx={{color: stateHasError && 'red  !important'}}>
                              State *
                            </CopytLabel>
                            <CreatableSelect
                              isDisabled={isGeneratingShipment || isDisabledField}
                              isValidNewOption={() => false}
                              styles={stateSelectFieldStyle}
                              className="react-select info"
                              id="location-autocomplete"
                              classNamePrefix="react-select"
                              placeholder="Select State"
                              closeMenuOnSelect
                              name="state *"
                              options={shipmentConstant?.states?.US.map((state) => ({
                                value: state.name,
                                label: state.name,
                              }))}
                              onChange={({value}) => {
                                handleChange(key, {name: 'state', value})
                              }}
                              value={{
                                value: addresses[key]?.state,
                                label: addresses[key]?.state,
                              }}
                            />
                            {(key === 'sender' && generateShipmentError?.address_from?.state) ||
                            (key === 'recipient' && generateShipmentError?.address_to?.state) ? (
                              <Box color="red" fontSize="10px">
                                {(key === 'sender' &&
                                  generateShipmentError?.address_from?.state?._errors[0]) ||
                                  (key === 'recipient' &&
                                    generateShipmentError?.address_to?.state?._errors[0])}
                              </Box>
                            ) : null}
                          </Box>
                          <Box width="100%">
                            <FieldGroup
                              disabled={isGeneratingShipment || isDisabledField}
                              hasError={
                                (key === 'sender' && generateShipmentError?.address_from?.zip) ||
                                (key === 'recipient' && generateShipmentError?.address_to?.zip)
                              }
                              errorMessage={
                                (key === 'sender' &&
                                  generateShipmentError?.address_from?.zip?._errors[0]) ||
                                (key === 'recipient' &&
                                  generateShipmentError?.address_to?.zip?._errors[0])
                              }
                              sLabel="Postal Code/Zip *"
                              value={addresses[key]?.zip}
                              name="zip"
                              onChange={(e) => handleChange(key, e.target)}
                            />
                          </Box>
                        </Stack>

                        <Stack>
                          {key === 'sender' &&
                          generateShipmentError.shippo?.validation?.shipment?.senderAddress?.analysis
                            ?.validation_result?.value === 'invalid' ? (
                            <div>
                              {generateShipmentError?.shippo?.validation?.shipment?.senderAddress?.analysis?.validation_result?.reasons.map(
                                (reason) => (
                                  <Typography fontSize="12px" color="red" variant="p">
                                    {reason?.description}
                                  </Typography>
                                ),
                              )}
                            </div>
                          ) : null}
                          {key === 'recipient' &&
                          generateShipmentError?.shippo?.validation?.shipment?.recipientAddress?.analysis
                            ?.validation_result?.value === 'invalid' ? (
                            <div>
                              {generateShipmentError?.shippo?.validation?.shipment?.recipientAddress?.analysis?.validation_result?.reasons.map(
                                (reason) => (
                                  <Typography fontSize="12px" color="red" variant="p">
                                    {reason?.description}
                                  </Typography>
                                ),
                              )}
                            </div>
                          ) : null}
                        </Stack>
                      </>
                    )}
                  </Stack>
                </React.Fragment>
              )
            })}
          </Stack>

          {generatedShipmentErrors?.length ? (
            <Stack p={2}>
              <Typography fontSize="12px" color="red" variant="p">
                {generatedShipmentErrors[0]?.text}
              </Typography>
            </Stack>
          ) : null}

          <Stack
            direction="row"
            justifyContent="flex-end"
            pr={2}
            pt={2}
            pb={2}
            borderTop="1px solid #eee"
            spacing={2}
          >
            <CopytStyledButton
              variant="outline"
              color="primary"
              disabled={isGeneratingShipment}
              onClick={() => setModalType(ModalType.PACKAGE_DETAILS)}
            >
              Back
            </CopytStyledButton>
            <CopytStyledButton
              variant="contained"
              disabled={
                isGeneratingShipment ||
                !addresses?.sender?.phone ||
                !addresses?.recipient?.phone ||
                !addresses?.sender?.email ||
                !addresses?.recipient?.email ||
                !addresses?.sender?.name ||
                !addresses?.recipient?.name
              }
              onClick={() => onSaveChanges({generateShipment: 'NONE'})}
            >
              {isGeneratingShipment ? 'Validating...' : 'Continue'}
            </CopytStyledButton>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default MdlAddress
