export default [
  {value: 'RUB', label: '₽'},
  {value: 'CNY', label: '¥'},
  {value: 'EUR', label: '€'},
  {value: 'USD', label: '$'},
  {value: 'GHS', label: '¢'},
  {value: 'GBP', label: '£'},
  {value: 'KRW', label: '₩'},
  {value: 'CZK', label: 'Kč'},
  {value: 'NOK', label: 'kr'},
  {value: 'AED', label: 'dhs'},
  {value: 'PLN', label: 'zł'},
]
