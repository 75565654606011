import React, {memo, useCallback, useState, useRef, useEffect, useMemo} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useReactToPrint} from 'react-to-print'
import {Box, Grid} from '@mui/material'
import {useAuth0} from '@auth0/auth0-react'
import {
  chain,
  compact,
  find,
  flatMap,
  get,
  isArray,
  isEmpty,
  join,
  startCase,
  times,
  toLower,
  toNumber,
  uniq,
} from 'lodash'
import axios from 'axios'
import {captureException} from '@sentry/react'

import {ReactComponent as ListingFailureSvg} from 'assets/img/ListingFailure.svg'
import {apiConfig} from 'config'
import {paths} from 'constant/api'
import DetailView from 'components/DetailView'
import {useToastMessage, Enterprise} from 'components'
import useEnterKeyListener from 'hooks/useEnterKeyListener'
import {ModalType} from 'enums'
import {useGlobalStore} from 'provider/global_store/hook'
import {useCredential, useUser, useStoreSettings, useListingMethod} from 'service/hook'
import {usePlatformAccepted} from 'service/hook/use_platform'
import {useItem} from 'service/hook/useItem'
import {isConsigned} from 'util/model/product'
import {createRequestOptions} from 'util/network'
import {getFee} from 'util/model/consignment'
import {_checkForClover} from 'util/array_utils'
import {
  Overview,
  InventoryPricing,
  InventoryImages,
  Shipping,
  ConsignorInformation,
  InventoryTracking,
  InventoryPlatforms,
} from 'views_v2/modules/Inventory/components/cards'
import {ListingInventoryDetails} from 'views_v2/modules/Listings'
import InventoryModals from 'views_v2/modules/Inventory/components/InventoryModals'
import {BarcodeLabels} from 'views_v2/lib/components'
import {LoadingBar} from 'views_v2/lib/snippets'
import {getMerchant} from 'views_v2/modules/Listings/middleware/getMerchant'
import {useLoadData, useSneak} from '../hooks/viewItem.hook'
import {ViewItemTopBar} from './tabs'
import {Notes} from './cards'

export const ViewItem = (props) => {
  const {isEnterprise, supportedPlatforms} = useGlobalStore()
  const {user} = useUser()
  const history = useHistory()
  const {showToast} = useToastMessage()
  const {postItemDuplicate} = useItem()
  const {id} = useParams()
  const componentRef = useRef()
  const {getAccessTokenSilently} = useAuth0()
  const {data: marketPlatformsAccepted} = usePlatformAccepted()
  const {locations: settingsStoreLocations} = useStoreSettings()
  const {credential} = useCredential()

  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const [duplicateSizes, setDuplicateSizes] = useState([{}])
  const [value, setValue] = useState(null)
  const [printSize, setPrintSize] = useState({width: 3.5, height: 1.2})
  const [barcodeTemplates, setBarcodeTemplates] = useState([])
  const [isBarcodeLabelReady, setIsBarcodeLabelReady] = useState(false)
  const [formFields, setFormFields] = useState({
    quantity: 1,
    shippingCost: '0.00',
    soldPlatform: null,
    soldPrice: '',
    location: null,
  })
  const [cloverStoreLocations, setCloverStoreLocations] = useState([])
  const [cloverSelectedStoreLocation, setCloverSelectedStoreLocation] = useState()
  const [platformSelection, setPlatformSelection] = useState([])
  const [platformOptions, setPlatformOptions] = useState([])
  const [isDefaultLocationInserted, setIsDefaultLocationInserted] = useState(false)
  const [title, setTitle] = useState('Listing Failure')
  const [content, setContent] = useState(null)
  const [hasMop, setHasMop] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [isListingPriceBump, setIsListingPriceBump] = useState(false)
  const [listingMethod, updateListingMethod] = useListingMethod()
  const [showBarcodeTemplate, setShowBarcodeTemplate] = useState({})

  const prices = useSneak(value)

  const {data} = props.location

  const cloverPlatformOption = useMemo(
    () => platformOptions?.find((pl) => pl?.label?.toLowerCase() === 'clover'),
    [platformOptions],
  )

  const handleCheckbox = (id) => {
    const index = platformSelection.indexOf(id)
    if (index > -1) {
      setPlatformSelection(platformSelection.filter((i) => i !== id))
      if (id === cloverPlatformOption?.id) setCloverSelectedStoreLocation(undefined)
    } else {
      setPlatformSelection([...platformSelection, id])
      if (id === cloverPlatformOption?.id && !isEmpty(cloverStoreLocations)) {
        setCloverSelectedStoreLocation(cloverStoreLocations[0].value)
      }
    }
  }

  useEnterKeyListener({querySelectorToExecuteClick: '#markAsSoldConfirmBtn'})

  useLoadData({id, setValue, data})

  const routeChange = useCallback(
    (route, data) => {
      history.push({pathname: route, data: data})
    },
    [history],
  )

  const getMopInfo = async () => {
    try {
      const token = await getAccessTokenSilently()
      const response = await axios.get(
        `${apiConfig.api_url}/payment/method`,
        createRequestOptions(token),
      )

      setHasMop(response?.data?.data?.method?.object === 'payment_method')
    } catch (e) {
      captureException(e)
    }
  }

  const onAddSize = useCallback(() => setDuplicateSizes((v) => [...v, {}]), [])

  const handleDuplicate = useCallback(async () => {
    setLoading(true)

    const dupeArr = flatMap(duplicateSizes, (size) =>
      times(size.count, () => (size.size ? {size: size.size, desiredReturn: size.return} : null)),
    ).filter(Boolean)

    await postItemDuplicate(
      {productId: value.id, sizes: dupeArr},
      () => {
        setDuplicateSizes([{}])
        showToast({variant: 'success', body: 'Item successfully duplicated!'})
        routeChange('/admin/inventory/viewInventory')
        setLoading(false)
      },
      () => {
        showToast({variant: 'danger', body: 'Failed to duplicate.'})
        setLoading(false)
      },
    )
  }, [duplicateSizes, setDuplicateSizes, postItemDuplicate, value, routeChange])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    suppressErrors: true,
    documentTitle: `Copyt - ${printSize.width}x${printSize.height}`,
  })

  const handleUpdate = (e) => {
    const target = e.target
    setFormFields((s) => ({...s, [target.name]: target.value}))
  }

  const soldPlatformOptions = useMemo(() => [...platformOptions], [platformOptions])

  const markAsSold = async () => {
    try {
      const token = await getAccessTokenSilently()

      const r = await axios.post(
        `${paths.listing}/mark-sold`,
        {
          productId: id,
          platformId: formFields.soldPlatform.value || formFields.soldPlatform,
          price: Number(formFields.soldPrice),
          shippingFee: Number(formFields.shippingCost),
        },
        createRequestOptions(token),
      )

      routeChange(`/admin/listings/viewSold/${r.data.data.id}`)
    } catch (e) {
      captureException(e)
      setTitle('Listing failed to mark as sold')
      setContent(
        (e && e.response && e.response.data) ||
          'Item is currently consigned and cannot be mark as sold manually',
      )
      setModalType(ModalType.LISTING_FAILURES)
    }
  }

  const onFormSubmit = async () => {
    const price = toNumber(get(formFields, 'desiredReturn'))

    const formError = isEmpty(get(formFields, 'id'))
      ? 'List Item is required'
      : isEmpty(platformSelection)
      ? 'Platform is required'
      : !price
      ? 'List Price is required'
      : null

    const cloverRefId = cloverSelectedStoreLocation?.split(':')[2]

    const data = {
      price,
      productId: get(formFields, 'id'),
      platformIds: uniq(platformSelection),
      refIds: {clover: cloverRefId},
      listingPriceBump: isListingPriceBump,
      listingMethod,
    }

    if (!formError) {
      try {
        setAddLoading(true)
        const token = await getAccessTokenSilently()
        const response = await axios.post(`${paths.listing}`, data, createRequestOptions(token))

        setAddLoading(false)
        if (response.status === 201) {
          routeChange('/admin/inventory/viewInventory', {status: 'job created'})
        }
      } catch (err) {
        captureException(err)

        const failedPlatformModal = compact(
          platformSelection.map((id) => platformOptions.find((p) => p.id === id)),
        )
        setContent(
          <Box>
            Could not post to{' '}
            {join(
              failedPlatformModal.map(
                (fp, i) => (i > 0 && i === failedPlatformModal.length - 1 ? 'and ' : '') + fp.label,
              ),
              ', ',
            )}
            .
            <ListingFailureSvg />
          </Box>,
        )
        setModalType(ModalType.LISTING_FAILURES)
        setAddLoading(false)
      }
    }
  }

  useEffect(() => {
    const fetchPlatforms = async () => {
      try {
        const token = await getAccessTokenSilently()
        const response = await axios.get(`${paths.platform}?type=market`, createRequestOptions(token))
        const platforms = response.data.data
          .filter((platform) => !['paypal', 'checkbook'].includes(platform.name.toLowerCase()))
          .map((platform) => ({
            label: platform.name,
            id: platform.id,
            value: platform.id,
            hasCred: platform.credentials.length > 0,
          }))

        setPlatformOptions(platforms)

        const filteredPlatforms = platforms
          .filter((platform) => platform.hasCred)
          .map((platform) => platform.value)

        setPlatformSelection((prevState) => [...prevState, ...filteredPlatforms])
      } catch (e) {
        captureException(e)
      }
    }

    fetchPlatforms()
  }, [getAccessTokenSilently])

  useEffect(() => {
    if (
      isEmpty(formFields) ||
      !isArray(settingsStoreLocations) ||
      isEmpty(platformSelection) ||
      isDefaultLocationInserted
    )
      return

    const csl = find(cloverStoreLocations, (csl) =>
      csl?.label?.toLowerCase().includes(formFields?.location?.toLowerCase()),
    )

    if (csl) {
      setCloverSelectedStoreLocation(csl.value)
      setPlatformSelection((prevState) => [...prevState, csl.value.split(':')[1]])
      setIsDefaultLocationInserted(true)
    }
  }, [formFields, settingsStoreLocations, platformSelection, isDefaultLocationInserted])

  useEffect(() => {
    if (!credential || !isArray(settingsStoreLocations)) return

    if (credential.data) {
      const merchants = getMerchant(credential)
      const cloverMappedStoreLocations = chain(settingsStoreLocations)
        .map((settingsStoreLocation) => {
          const merchant = find(
            merchants,
            (m) => get(m, 'value.storeLocationId') === settingsStoreLocation.id,
          )
          if (merchant) {
            return {
              label: startCase(toLower(settingsStoreLocation.name)),
              value: `clover:${merchant.platform.id}:${merchant.refId}`,
            }
          }
        })
        .compact()
        .value()

      setCloverStoreLocations(cloverMappedStoreLocations)
    }
  }, [settingsStoreLocations, credential?.data])

  useEffect(() => {
    try {
      getMopInfo()
    } catch (e) {
      captureException(e)
    }
  }, [])

  useEffect(() => {
    const elsewhere = soldPlatformOptions.find((plat) => plat.label === 'Elsewhere')
    setFormFields({...formFields, soldPlatform: elsewhere})
  }, [soldPlatformOptions])

  useEffect(() => {
    if (value) {
      setFormFields({
        ...formFields,
        ...value,
      })
    }
  }, [value])

  if (!value || !supportedPlatforms) {
    return <LoadingBar />
  }

  const isListItemDisabled =
    _checkForClover(platformOptions, platformSelection) && !cloverSelectedStoreLocation

  return (
    <Box className="p-detail-flex inventory-view-item-page">
      <ViewItemTopBar
        {...{
          formFields,
          id,
          setModalType,
          marketPlatformsAccepted,
          hasMop,
          platformSelection,
          cloverSelectedStoreLocation,
          addLoading,
          onFormSubmit,
          isListItemDisabled,
        }}
      />
      <DetailView.PanelDetail>
        {isBarcodeLabelReady && (
          <Box display="none">
            <BarcodeLabels
              {...{barcodeTemplates, showBarcodeTemplate}}
              items={[formFields]}
              businessName={user?.businessName || 'Copyt'}
              logo={user?.platform?.logo_url}
              url={user?.customWebsiteSearchUrl}
              ref={componentRef}
            />
          </Box>
        )}
        <Grid container gap={1}>
          <Grid item xs={12} lg={8}>
            <Grid container gap={2}>
              <Grid item xs={12} md={5.5} lg={5.8}>
                <Overview {...{formFields}} isListing readOnly />
              </Grid>
              <Grid item xs={12} md={6} lg={5.8}>
                <ListingInventoryDetails product={formFields} readOnly />
              </Grid>
            </Grid>
            <InventoryImages {...{formFields}} isListing />
          </Grid>
          <Grid item xs={12} lg={3.9}>
            <InventoryPricing
              {...{isEnterprise, formFields, isConsigned}}
              readOnly
              isDesiredReturnReadOnly
            />
            <InventoryPlatforms
              {...{
                isEnterprise,
                platformSelection,
                platformOptions,
                cloverStoreLocations,
                handleCheckbox,
                cloverSelectedStoreLocation,
                isListingPriceBump,
                setIsListingPriceBump,
                listingMethod,
                updateListingMethod,
              }}
              onChangeCloverDropdown={(e) => {
                if (e) {
                  setCloverSelectedStoreLocation(e.value)
                  setPlatformSelection([
                    ...platformSelection.filter(
                      (platform) => platform !== cloverSelectedStoreLocation?.split(':')[1],
                    ),
                    e.value.split(':')[1],
                  ])
                } else {
                  setPlatformSelection(
                    platformSelection.filter(
                      (platform) => platform !== cloverSelectedStoreLocation?.split(':')[1],
                    ),
                  )
                  setCloverSelectedStoreLocation(undefined)
                }
              }}
            />
            <Enterprise>
              <InventoryTracking
                {...{
                  setFormFields,
                  formFields,
                }}
                isEnterprise
                isReadOnly
                internalSku={value?.internalSku}
                location={value?.location}
              />
              <ConsignorInformation
                setFormFields={setFormFields}
                consignor={formFields?.consign}
                consignorEmail={formFields?.consign?.consignor?.email || ''}
                feeStructure={formFields?.feeStructure || getFee(formFields?.consign)}
                formFields={{
                  ...formFields,
                  desiredReturn: formFields?.desiredReturn,
                }}
                isConsignorEmailReadOnly
                isFeeStructureReadOnly
              />
              <Notes editMode={false} notes={formFields?.note} />
            </Enterprise>
          </Grid>
        </Grid>
        <InventoryModals
          {...{
            barcodeTemplates,
            content,
            duplicateSizes,
            formFields,
            loading,
            markAsSold,
            modalType,
            onAddSize,
            printSize,
            prices,
            setBarcodeTemplates,
            setFormFields,
            setDuplicateSizes,
            setModalType,
            setPrintSize,
            title,
            routeChange,
            showBarcodeTemplate,
          }}
          onShowBarcodeTemplate={setShowBarcodeTemplate}
          onHide={() => setModalType(ModalType.UNDEFINED)}
          onSubmit={handleDuplicate}
          onGenerateBarcodesClicked={() => {
            setIsBarcodeLabelReady(true)
            setTimeout(() => {
              handlePrint()
            }, 500)
          }}
          shouldUsePropFunc
        />
      </DetailView.PanelDetail>
    </Box>
  )
}

export default memo(ViewItem)
