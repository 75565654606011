import React from 'react'
import PropTypes from 'prop-types'
import clx from 'classnames'
import CopytModal from 'components/CopytModal'
import {ModalType} from 'enums'
import {
  MdlPaywall,
  MdlCreateBatch,
  MdlDeleteAll,
  MdlProcessAll,
  MdlDuplicateItem,
  MdlSkuUpc,
  MdlListingFailures,
  MdlUnsavedChanges,
  MdlWithdraw,
  MdlBarcodeTemplate,
  MdlCancelItem,
  MdlUploadImageFailed,
  MdlAdditionalFields,
  MdlBulkEdit,
  MdlBulkEditLayout,
  MdlCardOptions,
  MdlConsignmentContract,
  MdlListAllPlatform,
} from './modal-contents'
import {MdlDatePicker} from 'views_v2/modules/Orders/modal-contents'
import {MdlMarkAsSold} from 'views_v2/modules/Listings/modal-contents'

const ModalContents = ({children, common, focus, onCloseModal}) => {
  if (focus !== ModalType.UNDEFINED) {
    return React.Children.map(children, (c, i) => {
      if (c.props?.modalType === focus) {
        // Show modal based on the given modalType
        return React.cloneElement(c, {...common, onCloseModal})
      }
      return null
    })
  }
  return null
}

const InventoryModals = (p) => {
  const {modalType, setModalType, modals, refetch, updateStateValue} = p || {}
  const m = modals[modalType]

  const onClose = () => {
    if (modalType === ModalType.DELETE_ALL) refetch()
    if (modalType === ModalType.DUPLICATE_ITEM) p?.onHide()
    setModalType(ModalType.UNDEFINED)
    if (updateStateValue) updateStateValue('isDisabled', false)
  }

  return (
    <CopytModal
      className={m?.className || null}
      closeButton={m?.closeButton}
      id={m?.id}
      show={modalType > ModalType.UNDEFINED}
      size={m?.size || 'sm'}
      toggle={onClose}
    >
      <ModalContents focus={modalType} common={p} onCloseModal={onClose}>
        <MdlPaywall
          modalType={ModalType.PAYWALL}
          onSaveChanges={() => setModalType(ModalType.UNDEFINED)}
        />
        <MdlCreateBatch
          modalType={ModalType.CREATE_BATCH}
          onSaveChanges={() => setModalType(ModalType.UNDEFINED)}
        />
        <MdlDeleteAll
          modalType={ModalType.DELETE_ALL}
          onSaveChanges={() => {
            setModalType(ModalType.UNDEFINED)
          }}
        />
        <MdlProcessAll modalType={ModalType.PROCESS_ALL} />
        <MdlDuplicateItem modalType={ModalType.DUPLICATE_ITEM} />
        <MdlSkuUpc modalType={ModalType.SKU_UPC} />
        <MdlListingFailures modalType={ModalType.LISTING_FAILURES} />
        <MdlUnsavedChanges modalType={ModalType.CONTINUE} />
        <MdlWithdraw modalType={ModalType.WITHDRAW} />
        <MdlBarcodeTemplate modalType={ModalType.BARCODE_TEMPLATE} />
        <MdlCancelItem modalType={ModalType.CANCEL_ITEM} />
        <MdlUploadImageFailed modalType={ModalType.UPLOAD_IMAGE_FAILED} />
        <MdlAdditionalFields modalType={ModalType.ADDITIONAL_FIELDS} />
        <MdlDatePicker modalType={ModalType.DATE_PICKER} />
        <MdlCardOptions modalType={ModalType.CARD_OPTIONS} />
        <MdlMarkAsSold modalType={ModalType.MARK_AS_SOLD} />
        <MdlBulkEdit modalType={ModalType.BULK_EDIT} />
        <MdlBulkEditLayout modalType={ModalType.BULK_EDIT_LAYOUT} />
        <MdlConsignmentContract modalType={ModalType.CONSIGNMENT_CONTRACT} />
        <MdlListAllPlatform modalType={ModalType.LIST_ALL_PLATFORM} />
      </ModalContents>
    </CopytModal>
  )
}

InventoryModals.propTypes = {
  modals: PropTypes.object,
}

InventoryModals.defaultProps = {
  modals: {
    [ModalType.PAYWALL]: {
      className: 'paywall-modal',
      size: 'md',
    },
    [ModalType.CREATE_BATCH]: {
      className: clx('no-padding', 'create-batch-modal'),
      size: 'sm',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.DELETE_ALL]: {
      className: clx('no-padding', 'copyt_modal_container', 'delete-all-modal'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.PROCESS_ALL]: {
      className: clx('delete-all-modal'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.DUPLICATE_ITEM]: {
      className: clx('duplicate-modal'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.SKU_UPC]: {
      className: clx('sku-upc-modal'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.LISTING_FAILURES]: {
      size: 'xxs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.CONTINUE]: {
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.WITHDRAW]: {
      className: clx('withdraw-modal', 'no-padding'),
      size: 'md',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.BARCODE_TEMPLATE]: {
      className: clx('barcode-template-modal', 'no-padding'),
      size: 'sm',
    },
    [ModalType.CANCEL_ITEM]: {
      className: clx('no-padding'),
      size: 'xxs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.UPLOAD_IMAGE_FAILED]: {
      size: 'xxs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.ADDITIONAL_FIELDS]: {
      size: 'xs',
    },
    [ModalType.DATE_PICKER]: {
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.CARD_OPTIONS]: {
      className: clx('no_padding'),
      size: 'xxs',
    },
    [ModalType.MARK_AS_SOLD]: {
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.BULK_EDIT]: {
      className: clx('no_padding'),
      size: 'xxs',
    },
    [ModalType.BULK_EDIT_LAYOUT]: {
      className: clx('no_padding'),
      size: 'xxl',
    },
    [ModalType.CONSIGNMENT_CONTRACT]: {
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.LIST_ALL_PLATFORM]: {
      className: 'no-padding',
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
  },
}

export default InventoryModals
