import React, {useState} from 'react'
import {Box} from '@mui/material'
import CopytCard from 'components/CopytCard'
import {FieldGroup} from 'views_v2/lib/snippets'
import {CopytBtn} from './ProductTemplate'
import {useUser} from 'service/hook'

const QRCodeSettings = () => {
  const {user, customWebsiteSearchUrl} = useUser()
  const [value, setValue] = useState(user?.customWebsiteSearchUrl || '')

  return (
    <CopytCard className="copyt-card">
      <CopytCard.Header>
        <CopytCard.Text tag="div">QR Code Settings</CopytCard.Text>
      </CopytCard.Header>
      <CopytCard.Body>
        <FieldGroup
          sLabel="Website URL"
          name="customWebsiteSearchUrl"
          type="text"
          placeholder='Ex: "https://copyt.shop.com/search?q=[PRODUCT_TITLE]"'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          readOnly={customWebsiteSearchUrl.isLoading}
        />
        <Box display="flex" justifyContent="flex-end">
          <CopytBtn
            variant="contained"
            color="primary"
            isLoading={customWebsiteSearchUrl.isLoading}
            onClick={() => customWebsiteSearchUrl.mutate(value)}
          >
            Update Url
          </CopytBtn>
        </Box>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default QRCodeSettings
