import React from 'react'
import PropTypes from 'prop-types'
import CopytModal from 'components/CopytModal'
import {ModalType} from 'enums'
import {MdlBarcodeTemplate, MdlListAllPlatform} from '../Inventory/components/modal-contents'
import MdlBulkScanInDuplicate from './modals/MdlBulkScanInDuplicate'
import MdlBulkScanInCreateProducts from './modals/MdlBulkScanInCreateProducts'
import MdlBulkScanInListProducts from './modals/MdlBulkScanInListProducts'

const ModalContents = ({children, common, focus, onCloseModal}) => {
  if (focus !== ModalType.UNDEFINED) {
    return React.Children.map(children, (c, i) => {
      if (c.props?.modalType === focus) {
        // Show modal based on the given modalType
        return React.cloneElement(c, {...common, onCloseModal})
      }
      return null
    })
  }
  return null
}

const BulkScanInModals = (p) => {
  const {modalType, setModalType, modals} = p || {}
  const m = modals[modalType]

  const onClose = () => {
    setModalType(ModalType.UNDEFINED)
  }

  return (
    <CopytModal
      className={m?.className || 'no-padding'}
      closeButton={m?.closeButton}
      id={m?.id}
      show={modalType > ModalType.UNDEFINED}
      size={m?.size || 'sm'}
      toggle={onClose}
    >
      <ModalContents focus={modalType} common={p} onCloseModal={onClose}>
        <MdlListAllPlatform modalType={ModalType.LIST_ALL_PLATFORM} />
        <MdlBulkScanInDuplicate modalType={ModalType.BULK_SCAN_IN_DUPLICATE} />
        <MdlBarcodeTemplate modalType={ModalType.BARCODE_TEMPLATE} />
        <MdlBulkScanInCreateProducts modalType={ModalType.BULK_SCAN_IN_SUCCESS_CREATE} />
        <MdlBulkScanInListProducts modalType={ModalType.BULK_SCAN_IN_SUCCESS_LIST} />
      </ModalContents>
    </CopytModal>
  )
}

BulkScanInModals.propTypes = {
  modals: PropTypes.object,
}

BulkScanInModals.defaultProps = {
  modals: {
    [ModalType.LIST_ALL_PLATFORM]: {
      className: 'no-padding',
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.BULK_SCAN_IN_DUPLICATE]: {
      className: 'no-padding',
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.BARCODE_TEMPLATE]: {
      className: 'barcode-template-modal no-padding',
      size: 'sm',
    },
    [ModalType.BULK_SCAN_IN_SUCCESS_CREATE]: {
      className: 'no-padding',
      size: 'xl',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.BULK_SCAN_IN_SUCCESS_LIST]: {
      className: 'no-padding',
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
  },
}

export default BulkScanInModals
